import React, { Component } from 'react'
import {
  Card,
  Col,
  Row,
  Icon,
  Table,
  List,
  Radio,
  DatePicker,
  Spin,
  Tag,
  Switch,
  Tooltip,
  Divider,
  Checkbox,
  Button,
} from 'antd'
import styles from './style.less'
import moment from 'moment'
import socketio, { io } from 'socket.io-client'
import _ from 'lodash'
import { Tooltip as BarTooltip, Pie, PieChart, Cell, Label } from 'recharts'

import Request, { getCallColor } from '../../request'
import ReportTable from './ReportTable'
import Emitter from '../../emitter'
import { TableComp, FormUtils as GetAllFormFields } from 'sz-react-utils'

import { getUrlPushWrapper } from '../../routes'
import { notification } from 'antd/lib/index'
import OrderStats from '../../components/DashComp/orderStats'
import ComplaintStats from '../../components/DashComp/complaintStats'
import { LineChartOutlined } from '@ant-design/icons'

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)

  return (
    <text
      x={x}
      y={y}
      style={{ fontSize: '8px' }}
      fill="white"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  )
}
const RADIAN = Math.PI / 180

class Dashboard extends Component {
  loadApisRequest = () => {
    this.setState({ startLoading: true })

    Request.getAllLeads({
      wonDate: {
        $gte: this.state.startRange,
        $lte: this.state.lastRange,
      },
      status: 'won',
      sortField: 'createdAt',
      sortOrder: 'desc',
      results: '10000',
      disableDate: this.state.disableDate,
      stage: {
        $in: [
          'New (Untouched)',
          'Contacted',
          // 'Proposal Presented',
          // 'Followup (New Untouched)',
          // 'Contacted Followup',
          // 'Overdue Followup',
          'Today Followup',
        ],
      },
    }).then((data) => {
      this.setState({ closingLeadsData: data.data })
    })

    Request.getLeadsCountForAdmin({
      leadDate: { $gte: this.state.startRange, $lte: this.state.lastRange },
      disableDate: this.state.disableDate,
    }).then((data) => {
      this.setState({
        leadCount: data.allLeads,
        unqualifiedLeads: data.unqualifiedLeads,
        wonLeads: data.wonLeads,
        lostLeads: data.lostLeads,
      })
    })

    Request.getAllProducts({ results: '10000', count: '10000' }).then(
      (data) => {
        this.setState({ productCount: data.total })
      }
    )
    Request.getTaskCountForDashboard({
      leadDate: {
        $gte: this.state.startRange,
        $lte: this.state.lastRange,
      },
      disableDate: this.state.disableDate,
    }).then(({ data: taskCount }) => {
      this.setState({ taskCount })
    })
    Request.leadsStageCount({
      leadDate: { $lte: moment().endOf('day').toDate() },
      status: ['Open'],
    }).then(({ data }) => {
      this.setState({ leadStageCount: data })
    })
    Request.dateWiseFollowupCount({
      followUpDate: {
        $gte: this.state.startRange,
        $lte: this.state.lastRange,
      },
      disableDate: this.state.disableDate,
    }).then(({ data }) => {
      this.setState({ followupsCount: data })
    })
    Request.dateWiseActivitiesCount({}).then(({ data }) => {
      this.setState({ activitiesCount: data })
    })

    Request.leadTypeChart({
      leadDate: { $gte: this.state.startRange, $lte: this.state.lastRange },
      disableDate: this.state.disableDate,
    }).then(({ data: leadTypeChart }) => {
      this.setState({ leadTypeChart })
    })

    this.setState({ startLoading: false })
  }

  constructor(props) {
    super(props)
    this.table = React.createRef()
    this.state = {
      startRange: moment().startOf('day').toDate(),
      lastRange: moment().endOf('day').toDate(),
      currentPage: 1,
      userType: ['Admin', 'RO', 'Dispatch', 'Technician'],
      changeStatus: [],
      key: null,
      disableDate: false,
      newData: ['render'],
    }
  }
  onChange = (e) => {
    this.setState(
      {
        disableDate: e.target.checked,
      },
      () => {
        this.loadApisRequest()
      }
    )
  }
  apiRequest = (params) => {
    if (params.page) {
      this.state.currentPage = params.page
    }
    params.userType = 'RO'
    if (params.results && params.page) {
      this.setState({ changeStatus: [] })
    }
    return new Promise(async (resolve) => {
      let data = await Request.loadUser({
        ...params,
        regExFilters: ['mobile', 'name', 'email'],
      })

      _.each(data.data, (item, i) => {
        if (item.isLoggedIn) {
          this.changeLoginStatus(i, item.isLoggedIn)
        }
      })
      resolve(data)
    })
  }

  componentDidMount() {
    this.loadApisRequest()
    Emitter.on('reloadUsers', () => {
      this.table && this.table.current && this.table.current.reload()
    })
  }

  componentWillUnmount() {
    Emitter.off('reloadUsers', () => {})
  }

  updateUser = async (values) => {
    let x = await Request.updateUserDetails(values)
    if (!x.error) {
      notification.success({
        message: x.message,
      })
    } else {
      console.log('err is', x.error)
      notification.error({
        message: 'Error Saving',
        description: x.message,
      })
    }
  }

  changeLoginStatus = (key, value, userId) => {
    let { changeStatus } = this.state
    let newValue = _.clone(changeStatus)
    newValue[key] = value
    this.setState({ changeStatus: newValue }, () => {
      if (userId) this.updateUser({ _id: userId, isLoggedIn: value })
    })
  }

  render() {
    const {
      leadTypeChart,
      lostLeads,
      wonLeads,
      closingLeadsData,
      productCount,
      leadCount,
      taskCount,
      leadStageCount,
      activitiesCount,
      followupsCount,
      unqualifiedLeads,
      currentPage,
      userType,
      disableDate,
    } = this.state
    const columns = [
      {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        width: 250,
      },
      {
        title: 'Company',
        dataIndex: 'company',
        key: 'company',
        width: 180,
      },
      {
        title: 'Relationship Officer',
        dataIndex: 'officer',
        key: 'officer',
        width: 180,
        render: (val, record) => {
          return (
            <div>
              <div>{val && val.name}</div>
              <div>{val && val.email}</div>
              <div>{val && val.mobile}</div>
            </div>
          )
        },
      },
      {
        title: 'Lead Source',
        dataIndex: 'source',
        key: 'source',
        width: 150,
      },
      {
        title: 'Lead Stage',
        dataIndex: 'stage',
        key: 'stage',
        width: 150,
      },

      {
        title: 'Lead Status',
        key: 'status',
        render: (text, record) => {
          return (
            <div
              style={{
                backgroundColor: '#43BE31',
                textAlign: 'center',
                color: 'white',
                padding: '2px 2px',
                borderRadius: '2px',
                minWidth: '30px',
              }}>
              {record.status ? record.status : ''}
            </div>
          )
        },
      },
      /* {
               title: 'Priority',
               key: 'priority',
               render: (text, record) => {
                 return (
                   <div>{record.priority ? record.priority : ''}</div>
                 )
               }
             }*/
    ]
    const handleChangeDebut = (range) => {
      if (!range.length) {
        this.setState({
          startRange: moment().startOf('month').toDate(),
          lastRange: moment().endOf('month').toDate(),
        })
      } else {
        const valueOfInput1 = moment(range[0]).startOf('day').format()
        const valueOfInput2 = moment(range[1]).endOf('day').format()
        this.setState({
          ...this.state,
          startRange: valueOfInput1,
          lastRange: valueOfInput2,
        })
      }
      this.setState({ key: Date.now() })
      setTimeout(() => {
        this.loadApisRequest()
      }, 500)
    }

    const ROColumns = [
      {
        title: 'S.No',
        dataIndex: 'sno',
        width: 50,
        key: 'sno',
        //  fixed: 'left',
        render: (value, item, index) => (currentPage - 1) * 10 + index + 1,
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        searchTextName: 'name',
      },

      {
        title: 'Mobile',
        dataIndex: 'mobile',
        key: 'mobile',
        searchTextName: 'mobile',
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        searchTextName: 'email',
      },
      {
        title: 'Logged In',
        dataIndex: 'isLoggedIn',
        key: 'isLoggedIn',
        render: (val, record, key) => {
          return (
            <Switch
              checked={this.state.changeStatus[key]}
              onChange={(val) => {
                this.changeLoginStatus(key, val, record && record._id)
              }}></Switch>
          )
        },
        filters: [
          { value: true, text: 'Online' },
          { value: false, text: 'Offline' },
        ],
      },

      {
        title: 'Last Call',
        dataIndex: 'lastCallLogDetails',
        key: 'lastCallLogDetails',
        sorter: true,
        render: (ii, record) => {
          return (
            <div>
              <small>
                {ii && ii.callingTime
                  ? moment(ii.callingTime).format('lll')
                  : ''}
              </small>
              {ii && ii.duration ? (
                <small>
                  <br />
                  Duration: {moment.utc(ii.duration * 1000).format('HH:mm:ss')}
                </small>
              ) : (
                ''
              )}
              <br />
              {ii && ii.callType ? (
                <small>
                  {
                    <Tag
                      style={getCallColor(
                        ii.callType.toLowerCase(),
                        ii && ii.duration
                      )}>
                      {ii.callType}
                    </Tag>
                  }
                </small>
              ) : (
                ''
              )}
            </div>
          )
        },
        // width:100
      },
      {
        title: 'Inactive Since',
        dataIndex: 'inActiveTime',
        key: 'inActiveTime',
        sorter: true,
        searchDateName: 'inActiveTime',
        render: (val, record) => {
          return (
            record &&
            record.inActiveTime && (
              <Tooltip title={moment(record.inActiveTime).format('lll')}>
                {moment(record.inActiveTime).fromNow()}
              </Tooltip>
            )
          )
        },
        // width:100
      },
    ]

    let sumofCharts =
      leadTypeChart &&
      leadTypeChart.reduce(
        (accumulator, currentValue) => accumulator + currentValue.value,
        0
      )

    return (
      <div>
        <div className="site-card-wrapper">
          <Card style={{ height: '220px' }}>
            <Row>
              <Col span={13}>
                <Row
                  gutter={15}
                  style={{
                    marginTop: '10px',
                  }}>
                  <Col span={6}>
                    <h3
                      style={{
                        display: 'inline-block',
                        color: '#2B2539',
                        fontWeight: '600',
                        whiteSpace: 'nowrap',
                      }}>
                      <img
                        src="/test/leads-stats.svg"
                        alt="Icon"
                        style={{
                          height: '13px',
                          marginRight: '8px',
                          marginBottom: '4px',
                        }}
                      />
                      Leads Stats
                    </h3>
                  </Col>

                  <Col span={6}>
                    <DatePicker.RangePicker
                      disabled={disableDate}
                      style={{ width: '100%' }}
                      format="DD-MM-YYYY"
                      value={[
                        moment(this.state.startRange),
                        moment(this.state.lastRange),
                      ]}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      onChange={(value, dateString) => handleChangeDebut(value)}
                    />
                  </Col>
                </Row>

                <Row gutter={16} style={{ marginTop: 30 }}>
                  <Col span={6}>
                    <div
                      style={{
                        background: ' rgba(239, 200, 200, 0.3)',
                      }}
                      className={styles.card}>
                      {leadStageCount && leadStageCount.untouchedCount > 0 ? (
                        <a
                          target={'_blank'}
                          href={`/openStage/${moment(
                            this.state.lastRange
                          ).format('YYYY-MM-DD HH:mm')}/NewUntouched`}>
                          <div
                            className={styles.cardBlock}
                            style={{
                              background: ' rgba(239, 200, 200, 1)',
                            }}>
                            <h6 className={styles.cardHeading}>Untouched</h6>
                          </div>
                          <h1 className={styles.cardNumber}>
                            {leadStageCount.untouchedCount}
                          </h1>
                        </a>
                      ) : (
                        <div>
                          <div
                            className={styles.cardBlock}
                            style={{
                              background: ' rgba(239, 200, 200, 1)',
                            }}>
                            <h6 className={styles.cardHeading}>Untouched</h6>
                          </div>
                          <h1 className={styles.cardNumber}>0</h1>
                        </div>
                      )}
                    </div>
                  </Col>

                  {/* <Col span={6}>
                    <div
                      style={{
                        background: ' rgba(190, 211, 204, 0.3)',
                      }}
                      className={styles.card}>
                      {leadStageCount && leadStageCount.negotiationCount > 0 ? (
                        <a
                          target={'_blank'}
                          href={`/openStage/${moment(
                            this.state.lastRange
                          ).format('YYYY-MM-DD HH:mm')}/In Negotiation`}>
                          <div
                            className={styles.cardBlock}
                            style={{
                              background: ' rgba(190, 211, 204, 1)',
                            }}>
                            <h6 className={styles.cardHeading}>Negotiation</h6>
                          </div>
                          <h1 className={styles.cardNumber}>
                            {leadStageCount.negotiationCount}
                          </h1>
                        </a>
                      ) : (
                        <div>
                          <div
                            className={styles.cardBlock}
                            style={{
                              background: ' rgba(190, 211, 204, 1)',
                            }}>
                            <h6 className={styles.cardHeading}>Negotiation</h6>
                          </div>
                          <h1 className={styles.cardNumber}>0</h1>
                        </div>
                      )}
                    </div>
                  </Col> */}

                  <Col span={6}>
                    <div
                      style={{
                        background: ' rgba(198, 184, 222, 0.3)',
                      }}
                      className={styles.card}>
                      {leadStageCount && leadStageCount.contactedCount > 0 ? (
                        <a
                          target={'_blank'}
                          href={`/openStage/${moment(
                            this.state.lastRange
                          ).format('YYYY-MM-DD HH:mm')}/Contacted`}>
                          <div
                            className={styles.cardBlock}
                            style={{
                              background: ' rgba(198, 184, 222, 1)',
                            }}>
                            <h6 className={styles.cardHeading}>Contacted</h6>
                          </div>
                          <h1 className={styles.cardNumber}>
                            {leadStageCount.contactedCount}
                          </h1>
                        </a>
                      ) : (
                        <div>
                          <div
                            className={styles.cardBlock}
                            style={{
                              background: ' rgba(198, 184, 222, 1)',
                            }}>
                            <h6 className={styles.cardHeading}>Contacted</h6>
                          </div>
                          <h1 className={styles.cardNumber}>0</h1>
                        </div>
                      )}
                    </div>
                  </Col>

                  {/* <Col span={6}>
                    <div
                      style={{
                        background: ' rgba(238, 239, 200, 0.3)',
                      }}
                      className={styles.card}>
                      {leadStageCount && leadStageCount.proposalCount > 0 ? (
                        <>
                          <div
                            className={styles.cardBlock}
                            style={{
                              background: ' rgba(238, 239, 200, 1)',
                            }}>
                            <h6 className={styles.cardHeading}>Proposal</h6>
                          </div>
                          <h1 className={styles.cardNumber}>
                            {leadStageCount.proposalCount}
                          </h1>
                        </>
                      ) : (
                        <div>
                          <div
                            className={styles.cardBlock}
                            style={{
                              background: ' rgba(238, 239, 200, 1)',
                            }}>
                            <h6 className={styles.cardHeading}>Proposal</h6>
                          </div>
                          <h1 className={styles.cardNumber}>0</h1>
                        </div>
                      )}
                    </div>
                  </Col> */}
                </Row>
              </Col>

              <Col span={11}>
                {/* <Checkbox onChange={this.onChange}>Get All Data Count</Checkbox> */}
                <Row gutter={16}>
                  <Col
                    span={14}
                    xl={{ push: 6 }}
                    lg={{ push: 4 }}
                    md={{ push: 3 }}>
                    <div style={{ position: 'relative', marginTop: '10px' }}>
                      <PieChart
                        width={200}
                        height={150}
                        onMouseEnter={this.onPieEnter}>
                        {/* <BarTooltip /> */}
                        <Pie
                          data={leadTypeChart}
                          cx={70}
                          cy={70}
                          innerRadius={50}
                          outerRadius={70}
                          fill="#8884d8"
                          dataKey="value"
                          paddingAngle={2}
                          // labelLine={false}
                          // label={renderCustomizedLabel}
                        >
                          {leadTypeChart &&
                            leadTypeChart.map((entry, index) => {
                              if (entry.name === 'IndiaMART Api') {
                                return (
                                  <Cell
                                    key={`cell-${index}`}
                                    fill={'#7963CD'}
                                  />
                                )
                              } else if (entry.name === 'aajjo') {
                                return (
                                  <Cell
                                    key={`cell-${index}`}
                                    fill={'#5BC7E6'}
                                  />
                                )
                              } else if (entry.name === 'Facebook Ads') {
                                return (
                                  <Cell
                                    key={`cell-${index}`}
                                    fill={'#468BFA'}
                                  />
                                )
                              } else {
                                return (
                                  <Cell
                                    key={`cell-${index}`}
                                    fill={'#FF8F78'}
                                  />
                                )
                              }
                            })}
                        </Pie>
                      </PieChart>
                      <div
                        style={{
                          position: 'absolute',
                          left: '62px',
                          top: '38%',
                          fontSize: '12px',
                          fontWeight: '400',
                        }}>
                        {sumofCharts ? (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                            }}>
                            <span>Total</span>
                            <span
                              style={{ fontSize: '13px', fontWeight: '800' }}>
                              {sumofCharts}
                            </span>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </Col>

                  <Col span={10}>
                    <ul className={styles.dashboardList}>
                      <div>
                        <div className={styles.indiaMart}>
                          <span className={styles.indiaMartCircle}>
                            India Mart
                          </span>
                          <span style={{ fontWeight: '700' }}>
                            {leadTypeChart &&
                            leadTypeChart.find(
                              (item) => item.name === 'IndiaMART Api'
                            )
                              ? `${Math.round(
                                  (leadTypeChart.find(
                                    (item) => item.name === 'IndiaMART Api'
                                  ).value /
                                    sumofCharts) *
                                    100
                                )}%`
                              : '0%'}
                          </span>
                        </div>
                        <hr className={styles.line} />
                      </div>
                      <div>
                        <div className={styles.inbound}>
                          <span className={styles.inboundCircle}>Inbound</span>
                          <span style={{ fontWeight: '700' }}>
                            {leadTypeChart &&
                            leadTypeChart.find(
                              (item) => item.name === 'Inbound'
                            )
                              ? `${Math.round(
                                  (leadTypeChart.find(
                                    (item) => item.name === 'Inbound'
                                  ).value /
                                    sumofCharts) *
                                    100
                                )}%`
                              : '0%'}
                          </span>
                        </div>
                        <hr className={styles.line} />
                      </div>
                      <div>
                        <div className={styles.aajjo}>
                          <span className={styles.aajjoCircle}>Aajjo</span>
                          <span style={{ fontWeight: '700' }}>
                            {leadTypeChart &&
                            leadTypeChart.find((item) => item.name === 'aajjo')
                              ? `${Math.round(
                                  (leadTypeChart.find(
                                    (item) => item.name === 'aajjo'
                                  ).value /
                                    sumofCharts) *
                                    100
                                )}%`
                              : '0%'}
                          </span>
                        </div>
                        <hr className={styles.line} />
                      </div>
                      <div>
                        <div className={styles.facebookAds}>
                          <span className={styles.facebookAdsCircle}>
                            Facebook Ads
                          </span>
                          <span style={{ fontWeight: '700' }}>
                            {leadTypeChart &&
                            leadTypeChart.find(
                              (item) => item.name === 'Facebook Ads'
                            )
                              ? `${Math.round(
                                  (leadTypeChart.find(
                                    (item) => item.name === 'Facebook Ads'
                                  ).value /
                                    sumofCharts) *
                                    100
                                )}%`
                              : '0%'}
                          </span>
                        </div>
                        <hr className={styles.line} />
                      </div>
                    </ul>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>

          <Spin spinning={this.state.startLoading}>
            <Row gutter={16}>
              <Col span={8}>
                <Row style={{ marginBottom: '15px' }} gutter={15}>
                  <Col>
                    <Card
                      title={
                        <span style={{ fontSize: '16px' }}>
                          <img
                            src="/test/leads-icon.svg"
                            alt="Icon"
                            style={{
                              height: '15px',
                              marginRight: '5px',
                              marginBottom: '4px',
                            }}
                          />
                          Leads Count
                        </span>
                      }
                      bordered={false}
                      headStyle={{
                        // backgroundColor: '#6ab04c',
                        // textAlign: 'center',
                        // color: 'white',
                        fontSize: '15px',
                        fontWeight: '600',
                        borderBottom: 'none',
                        marginBottom: '-10px',
                        marginLeft: '-3px',
                      }}
                      bodyStyle={{ padding: '7px 20px', maxHeight: '236px' }}
                      style={{
                        // marginRight: '10px',
                        // height: '220px',
                        padding: '10px !important',
                        marginTop: '15px',
                      }}>
                      <List
                        size="small"
                        bordered={false}
                        dataSource={this.state.newData || []}
                        renderItem={(val) => (
                          <>
                            <List.Item>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-around',
                                  width: '100%',
                                }}>
                                <div
                                  style={{
                                    width: '60%',
                                    textAlign: 'start',
                                    color: '#686B75',
                                    fontWeight: '400',
                                  }}>
                                  All Leads
                                </div>
                                <div
                                  style={{
                                    width: '40%',
                                    textAlign: 'end',
                                  }}>
                                  <a
                                    target={'_blank'}
                                    style={{
                                      color: '#2B2539',
                                      fontWeight: '600',
                                    }}>
                                    {leadCount ? leadCount : 0}
                                  </a>
                                </div>
                              </div>
                            </List.Item>
                            <List.Item>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-around',
                                  width: '100%',
                                }}>
                                <div
                                  style={{
                                    width: '60%',
                                    textAlign: 'start',
                                    color: '#686B75',
                                    fontWeight: '400',
                                  }}>
                                  Unqualified Leads
                                </div>
                                <div
                                  style={{
                                    width: '40%',
                                    textAlign: 'end',
                                  }}>
                                  <a
                                    target={'_blank'}
                                    href={`/status/${moment(
                                      this.state.lastRange
                                    ).format('YYYY-MM-DD HH:mm')}/${moment(
                                      this.state.startRange
                                    ).format('YYYY-MM-DD HH:mm')}/Unqualified`}
                                    style={{
                                      color: '#2B2539',
                                      fontWeight: '600',
                                    }}>
                                    {unqualifiedLeads ? unqualifiedLeads : 0}
                                  </a>
                                </div>
                              </div>
                            </List.Item>
                            <List.Item>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-around',
                                  width: '100%',
                                }}>
                                <div
                                  style={{
                                    width: '60%',
                                    textAlign: 'start',
                                    color: '#686B75',
                                    fontWeight: '400',
                                  }}>
                                  Lost Leads
                                </div>
                                <div
                                  style={{
                                    width: '40%',
                                    textAlign: 'end',
                                  }}>
                                  <a
                                    target={'_blank'}
                                    href={`/status/${moment(
                                      this.state.lastRange
                                    ).format('YYYY-MM-DD HH:mm')}/${moment(
                                      this.state.startRange
                                    ).format('YYYY-MM-DD HH:mm')}/Lost`}
                                    style={{
                                      width: '40%',
                                      textAlign: 'end',
                                      color: '#2B2539',
                                      fontWeight: '600',
                                    }}>
                                    {lostLeads ? lostLeads : 0}
                                  </a>
                                </div>
                              </div>
                            </List.Item>
                            <List.Item>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-around',
                                  width: '100%',
                                }}>
                                <div
                                  style={{
                                    width: '60%',
                                    textAlign: 'start',
                                    color: '#686B75',
                                    fontWeight: '400',
                                  }}>
                                  Won Leads
                                </div>
                                <div
                                  style={{
                                    width: '40%',
                                    textAlign: 'end',
                                  }}>
                                  <a
                                    target={'_blank'}
                                    href={`/status/${moment(
                                      this.state.lastRange
                                    ).format('YYYY-MM-DD HH:mm')}/${moment(
                                      this.state.startRange
                                    ).format('YYYY-MM-DD HH:mm')}/won`}
                                    style={{
                                      width: '40%',
                                      textAlign: 'end',
                                      color: '#2B2539',
                                      fontWeight: '600',
                                    }}>
                                    {wonLeads ? wonLeads : 0}
                                  </a>
                                </div>
                              </div>
                            </List.Item>
                            <List.Item>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-around',
                                  width: '100%',
                                }}>
                                <div
                                  style={{
                                    width: '60%',
                                    textAlign: 'start',
                                    color: '#686B75',
                                    fontWeight: '400',
                                  }}>
                                  Example
                                </div>
                                <div
                                  style={{
                                    width: '40%',
                                    textAlign: 'end',
                                  }}>
                                  <a
                                    target={'_blank'}
                                    href={`/status/${moment(
                                      this.state.lastRange
                                    ).format('YYYY-MM-DD HH:mm')}/${moment(
                                      this.state.startRange
                                    ).format('YYYY-MM-DD HH:mm')}/won`}
                                    style={{
                                      width: '40%',
                                      textAlign: 'end',
                                      color: '#2B2539',
                                      fontWeight: '600',
                                    }}>
                                    {wonLeads ? wonLeads : 0}
                                  </a>
                                </div>
                              </div>
                            </List.Item>
                            <List.Item>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-around',
                                  width: '100%',
                                }}>
                                <div
                                  style={{
                                    width: '60%',
                                    textAlign: 'start',
                                    color: '#686B75',
                                    fontWeight: '400',
                                  }}>
                                  Example
                                </div>
                                <div
                                  style={{
                                    width: '40%',
                                    textAlign: 'end',
                                  }}>
                                  <a
                                    target={'_blank'}
                                    href={`/status/${moment(
                                      this.state.lastRange
                                    ).format('YYYY-MM-DD HH:mm')}/${moment(
                                      this.state.startRange
                                    ).format('YYYY-MM-DD HH:mm')}/won`}
                                    style={{
                                      width: '40%',
                                      textAlign: 'end',
                                      color: '#2B2539',
                                      fontWeight: '600',
                                    }}>
                                    {wonLeads ? wonLeads : 0}
                                  </a>
                                </div>
                              </div>
                            </List.Item>
                          </>
                        )}></List>
                    </Card>
                  </Col>
                </Row>
              </Col>
              <Col span={8}>
                <ComplaintStats
                  disableDate={this.state.disableDate}
                  startRange={this.state.startRange}
                  lastRange={this.state.lastRange}
                  key={this.state.key}
                />
              </Col>
              <Col span={8}>
                <OrderStats
                  startRange={this.state.startRange}
                  lastRange={this.state.lastRange}
                  key={this.state.key}
                  disableDate={disableDate}
                />
              </Col>
            </Row>

            <Row style={{ marginBottom: '15px' }} gutter={16}>
              <Col span={12}>
                <Card
                  title={
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                      }}>
                      <span style={{ fontSize: '16px' }}>
                        <img
                          src="/test/followup-icon.svg"
                          alt="Icon"
                          style={{
                            height: '15px',
                            marginRight: '5px',
                            marginBottom: '4px',
                          }}
                        />
                        Follow Ups (
                        {followupsCount && followupsCount.length > 0
                          ? followupsCount[0].count
                          : 0}
                        )
                      </span>
                      <Button
                        type="primary"
                        target="_blank"
                        href={
                          followupsCount && followupsCount.length > 0
                            ? `/followups/${moment(this.state.lastRange).format(
                                'YYYY-MM-DD HH:mm'
                              )}/${moment(this.state.startRange).format(
                                'YYYY-MM-DD HH:mm'
                              )}/${followupsCount[0].count}`
                            : null
                        }
                        style={{
                          padding: '0px 12px',
                          fontSize: '12px',
                        }}>
                        View All
                      </Button>
                    </div>
                  }
                  bordered={false}
                  headStyle={{
                    // backgroundColor: '#6ab04c',
                    // textAlign: 'center',
                    // color: 'white',
                    fontSize: '15px',
                    fontWeight: '600',
                    borderBottom: 'none',
                    marginBottom: '-10px',
                    marginLeft: '-3px',
                  }}
                  bodyStyle={{ padding: '7px 20px' }}
                  style={{
                    // marginRight: '10px',
                    height: '175px',
                    padding: '10px !important',
                    // marginTop: '10px',
                  }}>
                  <Row gutter={16}>
                    <Col span={12}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          width: '100%',
                          border: '1px solid #DDE0EB',
                          borderRadius: '5px',
                          padding: '20px 10px 17px 10px',
                        }}>
                        <div
                          style={{
                            display: 'flex',
                            // justifyContent: 'space-between',
                            // width: '100%',
                          }}>
                          <span style={{ fontSize: '16px' }}>
                            <img
                              src="/test/remaining-icon.svg"
                              alt="Icon"
                              style={{
                                height: '38px',
                                marginRight: '10px',
                                // marginBottom: '4px',
                              }}
                            />
                          </span>

                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                            }}>
                            <p
                              style={{
                                display: 'inline-block',
                                marginBottom: '-1px',
                                fontSize: '12px',
                                fontWeight: '400',
                                color: '#687691',
                              }}>
                              Remaining
                            </p>
                            <div style={{ display: 'inline-block' }}>
                              <a
                                target={'_blank'}
                                href={
                                  followupsCount && followupsCount.length > 0
                                    ? `/followups/${moment(
                                        this.state.lastRange
                                      ).format('YYYY-MM-DD HH:mm')}/${moment(
                                        this.state.startRange
                                      ).format('YYYY-MM-DD HH:mm')}/${
                                        followupsCount[2].count
                                      }`
                                    : null
                                }
                                style={{
                                  marginTop: '0px',
                                  marginBottom: '0px',
                                  fontSize: '18px',
                                  fontWeight: '600',
                                  color: '#343434',
                                }}>
                                {followupsCount && followupsCount.length > 0
                                  ? followupsCount[2].count
                                  : 0}
                              </a>
                            </div>
                          </div>
                        </div>

                        {followupsCount && followupsCount.length > 0 ? (
                          <div style={{ position: 'relative' }}>
                            <PieChart
                              width={86}
                              height={45}
                              onMouseEnter={this.onPieEnter}>
                              {/* <BarTooltip /> */}
                              <Pie
                                data={[followupsCount[2], followupsCount[1]]}
                                cx={40}
                                cy={37}
                                innerRadius={24}
                                outerRadius={40}
                                startAngle={180}
                                endAngle={0}
                                fill="#FBECCE"
                                dataKey="count"
                                paddingAngle={1}
                                // labelLine={false}
                                // label={renderCustomizedLabel}
                              >
                                {followupsCount &&
                                  followupsCount.map((entry, index) => {
                                    let fillColor = ''

                                    if (entry.type === 'Total') {
                                      fillColor = '#FBB214'
                                    } else if (entry.type === 'Completed') {
                                      fillColor = '#FBECCE'
                                    } else if (entry.type === 'Remaining') {
                                      fillColor = '#FBECCE'
                                    } else {
                                      fillColor = '#FBECCE'
                                    }

                                    return (
                                      <Cell
                                        key={`cell-${index}`}
                                        fill={fillColor}
                                      />
                                    )
                                  })}

                                {/* {leadTypeChart &&
                                leadTypeChart.map((entry, index) => {
                                  if (entry.type === 'Remaining') {
                                    return (
                                      <Cell
                                        key={`cell-${index}`}
                                        fill={'#7963CD'}
                                      />
                                    )
                                  } else if (entry.name === 'aajjo') {
                                    return (
                                      <Cell
                                        key={`cell-${index}`}
                                        fill={'#5BC7E6'}
                                      />
                                    )
                                  } else if (entry.name === 'Facebook Ads') {
                                    return (
                                      <Cell
                                        key={`cell-${index}`}
                                        fill={'#468BFA'}
                                      />
                                    )
                                  } else {
                                    return (
                                      <Cell
                                        key={`cell-${index}`}
                                        fill={'#FF8F78'}
                                      />
                                    )
                                  }
                                })} */}
                              </Pie>
                            </PieChart>
                            <div
                              style={{
                                position: 'absolute',
                                left: '34.5px',
                                top: '27px',
                                fontSize: '12px',
                                fontWeight: '700',
                              }}>
                              {followupsCount[2].count !== 0
                                ? `${Math.round(
                                    (followupsCount[2].count /
                                      followupsCount[0].count) *
                                      100
                                  )}%`
                                : ''}
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </Col>

                    <Col span={12}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          width: '100%',
                          border: '1px solid #DDE0EB',
                          borderRadius: '5px',
                          padding: '20px 10px 17px 10px',
                        }}>
                        <div
                          style={{
                            display: 'flex',
                            // justifyContent: 'space-between',
                            // width: '100%',
                          }}>
                          <span style={{ fontSize: '16px' }}>
                            <img
                              src="/test/completed-icon.svg"
                              alt="Icon"
                              style={{
                                height: '38px',
                                marginRight: '10px',
                                // marginBottom: '4px',
                              }}
                            />
                          </span>

                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                            }}>
                            <p
                              style={{
                                display: 'inline-block',
                                marginBottom: '-1px',
                                fontSize: '12px',
                                fontWeight: '400',
                                color: '#687691',
                              }}>
                              Completed
                            </p>
                            <div style={{ display: 'inline-block' }}>
                              <a
                                target={'_blank'}
                                href={
                                  followupsCount && followupsCount.length > 0
                                    ? `/followups/${moment(
                                        this.state.lastRange
                                      ).format('YYYY-MM-DD HH:mm')}/${moment(
                                        this.state.startRange
                                      ).format('YYYY-MM-DD HH:mm')}/${
                                        followupsCount[2].count
                                      }`
                                    : null
                                }
                                style={{
                                  marginTop: '0px',
                                  marginBottom: '0px',
                                  fontSize: '18px',
                                  fontWeight: '600',
                                  color: '#343434',
                                }}>
                                {followupsCount && followupsCount.length > 0
                                  ? followupsCount[1].count
                                  : 0}
                              </a>
                            </div>
                          </div>
                        </div>

                        {followupsCount && followupsCount.length > 0 ? (
                          <div style={{ position: 'relative' }}>
                            <PieChart
                              width={86}
                              height={45}
                              onMouseEnter={this.onPieEnter}>
                              {/* <BarTooltip /> */}
                              <Pie
                                data={[followupsCount[1], followupsCount[2]]}
                                cx={40}
                                cy={37}
                                innerRadius={24}
                                outerRadius={40}
                                startAngle={180}
                                endAngle={0}
                                fill="#D5F4EB"
                                dataKey="count"
                                paddingAngle={1}
                                // labelLine={false}
                                // label={renderCustomizedLabel}
                              >
                                {followupsCount &&
                                  followupsCount.map((entry, index) => {
                                    let fillColor = ''

                                    if (entry.type === 'Total') {
                                      fillColor = '#63CEAE'
                                    } else if (entry.type === 'Completed') {
                                      fillColor = '#D5F4EB'
                                    } else {
                                      fillColor = '#D5F4EB'
                                    }

                                    return (
                                      <Cell
                                        key={`cell-${index}`}
                                        fill={fillColor}
                                      />
                                    )
                                  })}
                              </Pie>
                            </PieChart>
                            <div
                              style={{
                                position: 'absolute',
                                left: '34.5px',
                                top: '27px',
                                fontSize: '12px',
                                fontWeight: '700',
                              }}>
                              {followupsCount[1].count !== 0
                                ? `${Math.round(
                                    (followupsCount[1].count /
                                      followupsCount[0].count) *
                                      100
                                  )}%`
                                : ''}
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>

              <Col span={12}>
                <Card
                  title={
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                      }}>
                      <span style={{ fontSize: '16px' }}>
                        <img
                          src="/test/task-icon.svg"
                          alt="Icon"
                          style={{
                            height: '15px',
                            marginRight: '5px',
                            marginBottom: '4px',
                          }}
                        />
                        Tasks (
                        {taskCount && taskCount.length > 0
                          ? taskCount[0].count + taskCount[1].count
                          : 0}
                        )
                      </span>
                      <Button
                        type="primary"
                        target="_blank"
                        href={null}
                        style={{
                          padding: '0px 12px',
                          fontSize: '12px',
                        }}>
                        View All
                      </Button>
                    </div>
                  }
                  bordered={false}
                  headStyle={{
                    // backgroundColor: '#6ab04c',
                    // textAlign: 'center',
                    // color: 'white',
                    fontSize: '15px',
                    fontWeight: '600',
                    borderBottom: 'none',
                    marginBottom: '-10px',
                    marginLeft: '-3px',
                  }}
                  bodyStyle={{ padding: '7px 20px' }}
                  style={{
                    // marginRight: '10px',
                    height: '175px',
                    padding: '10px !important',
                    // marginTop: '10px',
                  }}>
                  <Row gutter={16}>
                    <Col span={12}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          width: '100%',
                          border: '1px solid #DDE0EB',
                          borderRadius: '5px',
                          padding: '20px 10px 17px 10px',
                        }}>
                        <div
                          style={{
                            display: 'flex',
                            // justifyContent: 'space-between',
                            // width: '100%',
                          }}>
                          <span style={{ fontSize: '16px' }}>
                            <img
                              src="/test/remaining-icon.svg"
                              alt="Icon"
                              style={{
                                height: '38px',
                                marginRight: '10px',
                                // marginBottom: '4px',
                              }}
                            />
                          </span>

                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                            }}>
                            <p
                              style={{
                                display: 'inline-block',
                                marginBottom: '-1px',
                                fontSize: '12px',
                                fontWeight: '400',
                                color: '#687691',
                              }}>
                              Pending
                            </p>
                            <div style={{ display: 'inline-block' }}>
                              <a
                                target={'_blank'}
                                href={
                                  taskCount && taskCount.length > 0
                                    ? `/taskManagment/${moment(
                                        this.state.lastRange
                                      ).format('YYYY-MM-DD HH:mm')}/${moment(
                                        this.state.startRange
                                      ).format('YYYY-MM-DD HH:mm')}/${
                                        taskCount[0].type
                                      }`
                                    : null
                                }
                                style={{
                                  marginTop: '0px',
                                  marginBottom: '0px',
                                  fontSize: '18px',
                                  fontWeight: '600',
                                  color: '#343434',
                                }}>
                                {taskCount && taskCount.length > 0
                                  ? taskCount[0].count
                                  : 0}
                              </a>
                            </div>
                          </div>
                        </div>

                        {taskCount && taskCount.length > 0 ? (
                          <div style={{ position: 'relative' }}>
                            <PieChart
                              width={86}
                              height={45}
                              onMouseEnter={this.onPieEnter}>
                              {/* <BarTooltip /> */}
                              <Pie
                                data={taskCount}
                                cx={40}
                                cy={37}
                                innerRadius={24}
                                outerRadius={40}
                                startAngle={180}
                                endAngle={0}
                                fill="#FBECCE"
                                dataKey="count"
                                paddingAngle={1}
                                // labelLine={false}
                                // label={renderCustomizedLabel}
                              >
                                {taskCount &&
                                  taskCount.map((entry, index) => {
                                    let fillColor = ''

                                    if (entry.type === 'Pending') {
                                      fillColor = '#FBB214'
                                    } else if (entry.type === 'Completed') {
                                      fillColor = '#FBECCE'
                                    } else {
                                      fillColor = '#FBECCE'
                                    }

                                    return (
                                      <Cell
                                        key={`cell-${index}`}
                                        fill={fillColor}
                                      />
                                    )
                                  })}
                              </Pie>
                            </PieChart>
                            <div
                              style={{
                                position: 'absolute',
                                left: '34.5px',
                                top: '27px',
                                fontSize: '12px',
                                fontWeight: '700',
                              }}>
                              {taskCount[0].count !== 0
                                ? `${Math.round(
                                    (taskCount[0].count /
                                      (taskCount[0].count +
                                        taskCount[1].count)) *
                                      100
                                  )}%`
                                : ''}
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </Col>

                    <Col span={12}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          width: '100%',
                          border: '1px solid #DDE0EB',
                          borderRadius: '5px',
                          padding: '20px 10px 17px 10px',
                        }}>
                        <div
                          style={{
                            display: 'flex',
                            // justifyContent: 'space-between',
                            // width: '100%',
                          }}>
                          <span style={{ fontSize: '16px' }}>
                            <img
                              src="/test/completed-icon.svg"
                              alt="Icon"
                              style={{
                                height: '38px',
                                marginRight: '10px',
                                // marginBottom: '4px',
                              }}
                            />
                          </span>

                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                            }}>
                            <p
                              style={{
                                display: 'inline-block',
                                marginBottom: '-1px',
                                fontSize: '12px',
                                fontWeight: '400',
                                color: '#687691',
                              }}>
                              Completed
                            </p>
                            <div style={{ display: 'inline-block' }}>
                              <a
                                target={'_blank'}
                                href={
                                  taskCount && taskCount.length > 0
                                    ? `/taskManagment/${moment(
                                        this.state.lastRange
                                      ).format('YYYY-MM-DD HH:mm')}/${moment(
                                        this.state.startRange
                                      ).format('YYYY-MM-DD HH:mm')}/${
                                        taskCount[1].type
                                      }`
                                    : null
                                }
                                style={{
                                  marginTop: '0px',
                                  marginBottom: '0px',
                                  fontSize: '18px',
                                  fontWeight: '600',
                                  color: '#343434',
                                }}>
                                {taskCount && taskCount.length > 0
                                  ? taskCount[1].count
                                  : 0}
                              </a>
                            </div>
                          </div>
                        </div>

                        {taskCount && taskCount.length > 0 ? (
                          <div style={{ position: 'relative' }}>
                            <PieChart
                              width={86}
                              height={45}
                              onMouseEnter={this.onPieEnter}>
                              {/* <BarTooltip /> */}
                              <Pie
                                data={taskCount}
                                cx={40}
                                cy={37}
                                innerRadius={24}
                                outerRadius={40}
                                startAngle={180}
                                endAngle={0}
                                fill="#D5F4EB"
                                dataKey="count"
                                paddingAngle={1}
                                // labelLine={false}
                                // label={renderCustomizedLabel}
                              >
                                {taskCount &&
                                  taskCount.map((entry, index) => {
                                    let fillColor = ''

                                    if (entry.type === 'Pending') {
                                      fillColor = '#63CEAE'
                                    } else if (entry.type === 'Completed') {
                                      fillColor = '#D5F4EB'
                                    } else {
                                      fillColor = '#D5F4EB'
                                    }

                                    return (
                                      <Cell
                                        key={`cell-${index}`}
                                        fill={fillColor}
                                      />
                                    )
                                  })}
                              </Pie>
                            </PieChart>
                            <div
                              style={{
                                position: 'absolute',
                                left: '34.5px',
                                top: '27px',
                                fontSize: '12px',
                                fontWeight: '700',
                              }}>
                              {taskCount[1].count !== 0
                                ? `${Math.round(
                                    (taskCount[1].count /
                                      (taskCount[0].count +
                                        taskCount[1].count)) *
                                      100
                                  )}%`
                                : ''}
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>

            <Row gutter={16}>
              <Card
                title={
                  <span style={{ fontSize: '16px', fontWeight: '600' }}>
                    Team's Utilisation
                  </span>
                }
                headStyle={{
                  borderBottom: 'none',
                  marginBottom: '-30px',
                  marginLeft: '6px',
                }}>
                <TableComp
                  ref={this.table}
                  columns={ROColumns}
                  apiRequest={this.apiRequest}
                  // extraProps={}

                  pagination={{
                    ...this.state.pagination,
                    defaultPageSize: 10,
                    pageSizeOptions: ['10', '25', '50', '100'],
                    showSizeChanger: true,
                    ...this.props.pagination,
                  }}
                />
              </Card>
            </Row>

            {/* <Row gutter={16}>
              <Card>
                <ReportTable />
              </Card>
            </Row> */}

            <Row gutter={16}>
              <Card
                title={
                  <span style={{ fontSize: '16px', fontWeight: '600' }}>
                    Won Leads
                  </span>
                }
                headStyle={{
                  borderBottom: 'none',
                  marginBottom: '-30px',
                  marginLeft: '6px',
                }}
                style={{ marginTop: '15px' }}>
                <Table
                  columns={columns}
                  dataSource={closingLeadsData}
                  bordered
                  pagination={false}
                  size="small"
                  scroll={{ y: 300, x: 1000 }}
                />
              </Card>
            </Row>
          </Spin>
        </div>
      </div>
    )
  }
}

export default Dashboard
