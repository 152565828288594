import React, { Component } from 'react'
import Board from 'react-trello'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { TableComp } from 'sz-react-utils'

import moment from 'moment'
import {
  Skeleton,
  Badge,
  Card,
  Tooltip,
  Tag,
  Popconfirm,
  notification,
  Modal,
  Switch,
  Avatar,
  Row,
  Col,
  Popover,
  Checkbox,
  Input,
  Button,
  Icon,
  Select,
  Drawer,
  Form,
  Radio,
  Spin,
} from 'antd'
import Request, { getLeadStageColor } from '../../../request'
import Color from 'color'
import _ from 'lodash'
import Highlighter from 'react-highlight-words'
import styles from './styles.less'
import { connect } from 'react-redux'
import memoizeOne from 'memoize-one'
import update from 'immutability-helper'
import { getUrlPushWrapper } from '../../../routes'
import { getColorClass } from '../../../colors'
import AddLead from '../add/modalIndex'
import UpdateLead from '../add/editLead'
import ViewLead from './viewLead'
import { Typography } from 'antd'
import { stateFilter } from '../../../states'
import VisibilitySensor from 'react-visibility-sensor'
import { addLeadActivity } from './helpers' // CommonJs : require('react-on-screen').default
import Image from '../../../assets/thumbs-up.png'

const { Paragraph } = Typography

const { Option } = Select

class AllLeads extends Component {
  constructor(props) {
    super(props)
    this.table = React.createRef()
    const { dispatch } = props
    // const paginate = 1;

    this.state = {
      leadStatuseArr: ['Open', 'Lost', 'won'],
      allContacts: [],
      showBoard: true,
      showTable: false,
      paginate: 1,
      leadStage: ['New (Untouched)', 'Contacted', 'Today Followup'],
      boardData: { lanes: [] },
      key: Math.random(),
      RoList: [],
      selectedRowKeys: [],
      selectedRO: undefined,
      allDeals: [],
      leadId: null,
      loadingLeads: true,
      followFilter: {},
      apiLoader: false,
      refreshRightSide: false,
      transferLoader: false,
      roLeadCount: null,
      sortOrder:'desc',
      totalLeads:0,
      selectedStage: '',
    }

    let searchParams = new URLSearchParams(this.props.search)

    let ROIdAsAdmin = searchParams.get('ROIdAsAdmin')

    this.state.ROIdAsAdmin = ROIdAsAdmin
  }

  handleAfterUpdate = () => {
    this.filterOnChange('all')

    //now
    // this.filterOnChange('today');
  }

  getTodos = async (stageArr, pages, filter = {}, isFollowup) => {
    if (isFollowup) {
      this.filterOnChange('all')

      //now
      // this.filterOnChange('today');
      return false
    }

    let { paginate, apiLoader } = this.state

    if (apiLoader) {
      return false
    }

    this.state.apiLoader = true
    if (!stageArr) stageArr = ['New (Untouched)', 'Contacted', 'Today Followup']

    let params = {
      results: pages ? pages * 20 : 20,
      page: pages ? 1 : paginate,
      count: 20,
      sortOrder: this.state.sortOrder,
    }
    if (filter._id) {
      this.state.followFilter = filter
      filter = { $or: [{ _id: { $in: filter._id } } ] }
    } else {
      let temp = (this.state.followFilter || {})._id || []
      filter = { $or: [{ _id: { $in: temp } }] }
    }

    let user = { ...JSON.parse(localStorage.getItem('user')) }
    let dataOld = _.clone(this.state.allDeals)

    let roQuery =
      user.userType == 'RO'
        ? { officer: user._id }
        : { officer: this.state.ROIdAsAdmin }

    let { data,total } = await Request.getAllLeadsByPost({
      done:{$ne:true},
      ...params,
      stage: {
        $in: this.state.selectedStage ? this.state.selectedStage : stageArr,
      },
      status: 'Open',
      isVault: false,
      isChild: false,
      $or: [
        { leadApprovedByAdmin: { $in: [true] } },
        { leadApprovedByAdmin: { $exists: false } },
      ],
      isTransferred: this.state.isTransferred
        ? this.state.isTransferred
        : false,
      ...roQuery,
      // ...filter,
    })
    if (!pages || pages == 1) {
      if (paginate == 1) {
        if (data && data[0]) {
          data[0].activeLead = true
        }
        if (this.state.leadId === null) {
          this.state.leadId = data && data[0] && data[0]._id
          this.state.refreshRightSide = data && data[0] && data[0]._id
        }
      }
    }
    let allDeals2 =
      data &&
      data.map((v) => {
        v.activeLead = false
        if (v._id == this.state.leadId) {
          v.activeLead = true
        }
        return v
      })
    if (data) {
      if (!pages) {
        allDeals2 = [...dataOld, ...allDeals2]
      }
    }
    if (allDeals2 && !allDeals2.length) {
      this.setState({ showImage: true })
    } else {
      this.setState({ showImage: false })
    }
    this.setState({
      allDeals: allDeals2,
      loadingLeads: false,
      apiLoader: false,
      transferLoader: false,
      totalLeads:total
    })
  }

  handleOk = (e) => {
    this.filterOnChange(this.state.filterValue)
    this.setState({
      followUpModal: false,
    })
  }

  handleCancel = (e) => {
    this.setState({
      followUpModal: false,
    })
  }

  handleAscendingSortClick = (e) => {
    this.setState(
      {
        sortOrder: 'ascend',
        apiLoader: false,
        transferLoader:true
      },
      () => {
        // This callback runs after the state has been updated
        this.getTodos(null, 1);
      }
    );
  }
  handleDescendingSortClick = (e) => {
    this.setState(
      {
        sortOrder: 'desc',
        apiLoader: false,
        transferLoader:true
      },
      () => {
        // This callback runs after the state has been updated
        this.getTodos(null, 1);
      }
    );
}

  showModal = () => {
    this.setState({
      followUpModal: true,
      key: Date.now(),
    })
  }

  openLeadDrawer = (leadId) => {
    this.setState({
      updateLeadDrawer: true,
      leadId,
    })
  }
  filterOnChange = (e) => {
    let { currentUser } = this.props
    this.setState({ filterValue: e, transferLoader: true })
    let startRange
    let lastRange
    let createdAtRange
    let filter = {
      addedBy: this.state.ROIdAsAdmin || (currentUser && currentUser._id),
      active: true,
    }
    if (e === 'today') {
      startRange = moment().startOf('day').format('YYYY-MM-DDTHH:MM:ss')
      lastRange = moment().endOf('day').toDate()
      createdAtRange = moment().subtract(2, 'hours').toDate()
    } else if (e === 'tomorrow') {
      startRange = moment().startOf('day').format('YYYY-MM-DDTHH:MM:ss')
      lastRange = moment()
        .add(1, 'days')
        .endOf('day')
        .format('YYYY-MM-DDTHH:MM:ss')
    } else if (e === 'week') {
      startRange = moment().startOf('day').format('YYYY-MM-DDTHH:MM:ss')
      lastRange = moment()
        .add(7, 'days')
        .endOf('day')
        .format('YYYY-MM-DDTHH:MM:ss')
    } else if (e === 'month') {
      startRange = moment().startOf('day').format('YYYY-MM-DDTHH:MM:ss')
      lastRange = moment()
        .add(30, 'days')
        .endOf('day')
        .format('YYYY-MM-DDTHH:MM:ss')
    } else {
      startRange = null
      lastRange = null
    }

    if (startRange && lastRange) {
      filter.customQuery = {
        $or: [
          {
            followUpDate: {
              $gte: startRange,
              $lte: lastRange,
            },
          },
          { followUpDate: { $lt: moment().startOf('day') } },
        ],
      }
    }

    if (createdAtRange) {
      filter.customQuery.createdAt = { $lte: createdAtRange }
    }
    Request.getROLeadsCount({ _id: this.state.ROIdAsAdmin }).then((resp) => {
      if (!resp.error) {
        this.setState({ roLeadCount: resp.data })
      }
    })
    Request.getAllFollowupsByLeadId({
      ...filter,
      results: 100000000000000,
    }).then(({ data: followupData }) => {
      let leadIds = _.map(followupData, (ii) => {
        return ii.leadId && ii.leadId._id
      })
      if (leadIds) {
        this.state.allDeals = []
        this.state.paginate = 1
        this.getTodos(null, null, { _id: leadIds })
      }
    })
  }

  async componentDidMount() {
    this.props.dispatch({ type: 'TOGGLE_SIDEBAR_COLLAPSED' })
    let user = { ...JSON.parse(localStorage.getItem('user')) }
    let { data: allContacts } = await Request.getAllContacts({ results: 10 })
    let firstRender = true
    //        this.filterOnChange('today')
    //this.getTodos();
    this.setState({ allContacts, sale: 'Sales' })
    let { data: userList } = await Request.getAllROList({
      customQuery: { userType: 'RO' },
    })
    let { data: overdueFollowups } = await Request.getAllFollowupsByLeadId({
      addedBy: this.state.ROIdAsAdmin || (user && user._id),
      active: true,
      followUpDate: { $lt: moment().startOf('day') },
    })
    if (overdueFollowups && overdueFollowups.length) {
      this.setState({ overdueFollowups: true })
    }

    // this.filterOnChange('today')

    this.filterOnChange('all')

    this.setState({ RoList: userList })
  }

  getAcitveStyle = (active) => {
    if (!active) {
      return {}
    }

    return {
      backgroundColor: 'white',
      borderRight: 0,
      width: 'calc(100% + 1px)',
    }
  }

  loadMore = () => {
    this.setState(
      {
        loadingLeads: true,
        paginate: this.state.paginate + 1,
      },
      () => {
        this.getTodos()
      }
    )
  }

  render() {
    const { currentUser, dispatch } = this.props
    let {
      allDeals,
      leadId,
      updateLeadDrawer,
      allContacts,
      drawerKey,
      loadingLeads,
      overdueFollowups,
      leadStage,
      ROIdAsAdmin,
    } = this.state
    const isTransferredLeadsFilterChange = (e) => {
      if (e.target.checked) {
        this.setState({ isTransferred: true }, () => {
          this.filterOnChange(this.state.filterValue)
        })
      } else {
        this.state.isTransferred = false
        this.filterOnChange(this.state.filterValue)
      }
    }

    const setSelectedStage = (value) => {
      if (this.state.selectedStage === value) {
        this.setState({ selectedStage: '' }, () => {
          this.getTodos(null, 1)
        })
      } else {
        this.setState({ selectedStage: value }, () => {
          this.getTodos(null, 1)
        })
      }
    }

    const resetLeadId = () => {
      this.setState({ leadId: null });
    }

    return (
      <>
        <Spin spinning={this.state.transferLoader}>
          {this.state.showImage ? (
            <div style={{ textAlign: 'center' }}>
              <img src={Image} style={{ width: '20%' }} alt="icon" />
              <div>
                <h1 style={{ fontSize: '30px' }}>All Done!</h1>
              </div>
            </div>
          ) : (
            <>
              <Row gutter={16} className={styles.mainLeadView}>
                <Col span={6} style={{ minHeight: '100%' }}>
                
                  <div id="cardCont" className={styles.cardCont}>
                  <div className={styles.todayAndSortDiv}>
                      <div className={styles.todayAndSortInnerDiv}>
                      <div style={{fontWeight:'600'}}> Total Leads ({this.state.totalLeads})</div>
                      <div>
                      {this.state.sortOrder === '' || this.state.sortOrder === 'desc' ? (
                        <Tooltip title='Sort by date in ascending order '>
                          <Button onClick={this.handleAscendingSortClick}><Icon type="up" /></Button>
                          </Tooltip>
                        ) : (
                          <Tooltip title='Sort by date in descending order '>
                          <Button onClick={this.handleDescendingSortClick}><Icon type="down" /></Button>
                          </Tooltip>
                        )}
                      </div>
                      </div>
                    </div>
                    {allDeals &&
                      allDeals.map((val, index) => {
                        let { activeLead } = val
                        let formattedValue = val.value
                          ? val.value.replace(/(\d)(?=(\d\d)+\d$)/g, '$1,')
                          : ''

                        return (
                          <>
                            <div
                              key={index}
                              className={`${styles.card} ${
                                leadId === val._id ? styles.cardActive : ''
                              }`}
                              style={this.getAcitveStyle(activeLead)}
                              onClick={() => {
                                this.setState({
                                  leadId: val._id,
                                  refreshRightSide: val._id,
                                })

                                let allDeals2 = allDeals.map((v) => {
                                  v.activeLead = false
                                  if (v._id == val._id) {
                                    v.activeLead = true
                                  }
                                  return v
                                })
                                this.setState({ allDeals: allDeals2 })
                              }}>
                              <div className={styles.title}>
                                #{val.id} -{' '}
                                <b style={{ color: '#2B2539' }}>{val.title}</b>{' '}
                                <span
                                  style={{
                                    marginLeft: '3px',
                                    color: '#4C4B4B',
                                  }}>
                                  {' '}
                                  <Tag
                                    visible={
                                      val.new == 'undefined' ? true : val.new
                                    }
                                    color="orange">
                                    New
                                  </Tag>
                                </span>
                                {val && val.overdueCount && (
                                  <span
                                    style={{
                                      marginLeft: '3px',
                                    }}>
                                    {' '}
                                    <Tag color="#EA3D66">
                                      OD ({val.overdueCount})
                                    </Tag>
                                    {/* <Badge
                                      count={`OD (${val.overdueCount})`}
                                      style={{ backgroundColor: '#EA3D66' }}
                                    />{' '} */}
                                  </span>
                                )}
                              </div>

                              <div className={styles.subtitle}>
                                <b>
                                  {val.contact ? val.contact.firstName : ''}
                                </b>

                                {/* <a
                                  style={{ marginLeft: '3px' }}
                                  href={`tel:${val.mobile}`}>
                                  {val.mobile}
                                </a> */}

                                <p
                                  className={styles.label}
                                  style={{
                                    backgroundColor: 'rgba(110, 151, 224, 0.4)',
                                    color: '#2B2539',
                                    // backgroundColor: getLeadStageColor(
                                    //   val.stage
                                    // ),
                                  }}>
                                  {val.stage}
                                </p>
                              </div>

                              <div className={styles.info}>
                                <p style={{ margin: '-7px 0 5px 0' }}>
                                  <img
                                    src={`/test/leads/phone.svg`}
                                    alt="Icon"
                                    style={{ margin: '0 2px 3px 0', width: '12px', height: '15px' }}
                                  />
                                  {val && val.mobile
                                    ? val.mobile
                                    : val && val.contact && val.contact.mobile
                                    ? val.contact.mobile
                                    : 'N/A'}
                                </p>

                                <p style={{ marginTop: '-7px' }}>
                                  {val && val.currency
                                    ? `${
                                        val.currency[val.currency.length - 1] + ' ' +
                                        formattedValue
                                      }`
                                    : '₹ 0.00\u00A0\u00A0'}
                                </p>

                                <p
                                  className={styles.state}
                                  style={{ marginTop: '-2px' }}>
                                  <img
                                    src={`/test/leads/location.svg`}
                                    alt="Icon"
                                    style={{ margin: '0 5px 3px 0' }}
                                  />
                                  {val.state}
                                  {val &&
                                  val.addressInfo &&
                                  val.addressInfo.city
                                    ? ` (${val.addressInfo.city})`
                                    : ''}
                                </p>
                              </div>

                              <div className={styles.actions}>
                                <span
                                  style={{
                                    color: '#2B2539',
                                    fontWeight: '500',
                                    fontSize: '11px',
                                  }}>
                                  <Icon
                                    type={'calendar'}
                                    theme="outlined"
                                    style={{
                                      fontSize: '15px',
                                      marginLeft: '0px',
                                    }}
                                    onClick={() => {
                                      this.openLeadDrawer(val._id)
                                    }}
                                  />
                                  {moment(val.leadReceivedDate).format('lll')}
                                </span>

                                <div style={{ marginRight: '13px' }}>
                                  <Tooltip title="Email sent">
                                    <Badge
                                      size="small"
                                      count={val.emails.length}
                                      style={{
                                        backgroundColor: '#3368c6',
                                        color: '#fff',
                                      }}>
                                      <Icon
                                        type={'mail'}
                                        theme="filled"
                                        className={styles.actionsIcon}
                                        onClick={() => {
                                          this.openLeadDrawer(val._id)
                                        }}
                                      />{' '}
                                    </Badge>
                                  </Tooltip>

                                  <Tooltip title="Notes">
                                    <Badge
                                      size="small"
                                      count={val.notes.length}
                                      style={{
                                        backgroundColor: '#3368c6',
                                        color: '#fff',
                                      }}>
                                      <Icon
                                        type={'file-text'}
                                        theme="filled"
                                        className={styles.actionsIcon}
                                        style={{
                                          marginLeft: '13px',
                                        }}
                                        onClick={() => {
                                          this.openLeadDrawer(val._id)
                                        }}
                                      />
                                    </Badge>
                                  </Tooltip>
                                </div>
                              </div>
                            </div>
                          </>
                        )
                      })}

                    {allDeals && allDeals.length == 0 && (
                      <div className={styles.card}>
                        <Skeleton
                          avatar
                          paragraph={{ rows: 2 }}
                          active={true}
                          loading={true}
                        />
                        {''}
                      </div>
                    )}

                    <VisibilitySensor
                      offset={{ top: 100 }}
                      partialVisibility
                      onChange={(isVisible) => {
                        if (isVisible && !loadingLeads) {
                          this.loadMore()
                        }
                      }}>
                      <div style={{ textAlign: 'center' }}>
                        <Button
                          onClick={this.loadMore}
                          size="small"
                          className={styles.loadMore}
                          loading={loadingLeads}
                          type="primary">
                          Load More
                        </Button>
                      </div>
                    </VisibilitySensor>
                  </div>
                </Col>

                <Col span={18}>
                  <Row
                    style={{ backgroundColor: 'white', overflowY: 'scroll' }}
                    className={styles.leadViewWrapper}>
                    <Row className={styles.leadViewWrapperInside}>
                      {this.state.roLeadCount !== null &&
                        Object.entries(this.state.roLeadCount).map(
                          ([key, value], index, array) => (
                            <div
                              key={key}
                              className={this.state.selectedStage === key ? styles.leadViewWrapperTextSelected : styles.leadViewWrapperText}
                              style={value === 0 ? {} : { cursor: 'pointer' }}
                              onClick={() => {
                                value && setSelectedStage(key);
                              }}
                              >
                              <img
                                src={`/test/leads/${key.split(' ')[0]}.svg`}
                                alt="Icon"
                                className={styles.leadViewWrapperIcon}
                              />
                              <p>{key} :</p>
                              <b
                                className={`${styles.leadViewWrapperTextBold} ${
                                  index === array.length - 1
                                    ? ''
                                    : styles.leadViewWrapperTextBorder
                                }`}>
                                {value}
                              </b>
                            </div>
                          )
                        )}
                    </Row>

                    {leadId && (
                      <ViewLead
                        key={this.state.refreshRightSide}
                        leadId={leadId}
                        resetLeadId={resetLeadId}
                        refresh={this.state.refreshRightSide}
                        getTodos={this.getTodos}
                        handleAfterUpdate={this.handleAfterUpdate}
                        paginate={this.state.paginate}
                      />
                    )}
                  </Row>
                </Col>
              </Row>

              <Drawer
                title=""
                placement="right"
                closable={true}
                onClose={async () => {
                  this.setState({
                    updateLeadDrawer: false,
                  })
                }}
                visible={updateLeadDrawer}
                width={800}>
                {/* cardId={cardId} */}
                <UpdateLead
                  cardId={leadId}
                  allContacts={allContacts}
                  leadOwner={ROIdAsAdmin || currentUser}
                  key={new Date()}
                  fromRoPage={true}
                  handleUpdateLead={() => {
                    this.setState({
                      updateLeadDrawer: false,
                      refreshRightSide: new Date(),
                      leadId: leadId,
                    })
                    // this.getTodos();
                    this.filterOnChange() //to refresh
                  }}
                />
              </Drawer>
            </>
          )}
        </Spin>
        <Modal
          visible={this.state.followUpModal}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          key={this.state.key}>
          <Radio.Group
            onChange={(val) => {
              this.setState({ filterValue: val.target.value })
            }}
            value={this.state.filterValue}
            defaultValue={this.state.filterValue}>
            <Radio className={styles.radioStyle} value={'today'}>
              Today
            </Radio>
            <Radio className={styles.radioStyle} value={'tomorrow'}>
              2 Days
            </Radio>
            <Radio className={styles.radioStyle} value={'week'}>
              7 Days
            </Radio>
            <Radio className={styles.radioStyle} value={'month'}>
              30 Days
            </Radio>
            <Radio className={styles.radioStyle} value={'all'}>
              All
            </Radio>
          </Radio.Group>
        </Modal>
      </>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  categories: global.categories,
  currentUser: global.currentUser,
  search: router.location.search,
})
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AllLeads)
