import { notification } from 'antd'
import axios from 'axios'
import _ from 'lodash'
import { apiUrl } from './settings'

export const API_URL = apiUrl

let authAxios = axios.create({
  baseURL: apiUrl,
})

let getToken = () => {
  return {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
  }
}

function download(record) {
  fetch(
    `${apiUrl}/recordingStream?callingTime=${record.callingTime}&duration=${record.duration}&roNumber=${record.roNumber}`,
    getToken()
  ).then(function (t) {
    let name = t.headers
      .get('Content-Disposition')
      .split('=')[1]
      .replace(/['"]+/g, '')
    return t.blob().then((b) => {
      var a = document.createElement('a')
      a.href = URL.createObjectURL(b)
      a.setAttribute('download', name)
      a.click()
    })
  })
}

export const countryList = [
  {
    countryName: 'Andorra',
    frenchName: 'Andorre',
    isoCode: 'AD',
  },
  {
    countryName: 'Republic of Kosovo',
    frenchName: 'Republic of Kosovo',
    isoCode: 'XK',
  },
  {
    countryName: 'Sint Maarten',
    frenchName: 'Saint Martin',
    isoCode: 'SX',
  },
  {
    countryName: 'Canary Islands',
    frenchName: 'Canaries, Iles',
    isoCode: 'IC',
  },
  {
    countryName: 'Bonaire',
    frenchName: 'Bonaire',
    isoCode: 'BQ',
  },
  {
    countryName: 'Curacao',
    frenchName: 'Curacao',
    isoCode: 'CW',
  },
  {
    countryName: 'Yugoslavia',
    frenchName: 'Yougoslavie',
    isoCode: 'YU',
  },
  {
    countryName: 'United Arab Emirates',
    frenchName: 'Émirats arabes unis',
    isoCode: 'AE',
  },
  {
    countryName: 'Afghanistan',
    frenchName: 'Afghanistan',
    isoCode: 'AF',
  },
  {
    countryName: 'Antigua and Barbuda',
    frenchName: 'Antigua-et-Barbuda',
    isoCode: 'AG',
  },
  {
    countryName: 'Anguilla',
    frenchName: 'Anguilla',
    isoCode: 'AI',
  },
  {
    countryName: 'Albania',
    frenchName: 'Albanie',
    isoCode: 'AL',
  },
  {
    countryName: 'Armenia',
    frenchName: 'Arménie',
    isoCode: 'AM',
  },
  {
    countryName: 'Netherlands Antilles',
    frenchName: 'Antilles néerlandaises',
    isoCode: 'AN',
  },
  {
    countryName: 'Angola',
    frenchName: 'Angola',
    isoCode: 'AO',
  },
  {
    countryName: 'Antarctica',
    frenchName: 'Antarctique',
    isoCode: 'AQ',
  },
  {
    countryName: 'Argentina',
    frenchName: 'Argentine',
    isoCode: 'AR',
  },
  {
    countryName: 'American Samoa',
    frenchName: 'Samoa américaines',
    isoCode: 'AS',
  },
  {
    countryName: 'Austria',
    frenchName: 'Autriche',
    isoCode: 'AT',
  },
  {
    countryName: 'Australia',
    frenchName: 'Australie',
    isoCode: 'AU',
  },
  {
    countryName: 'Aruba',
    frenchName: 'Aruba',
    isoCode: 'AW',
  },
  {
    countryName: 'Åland',
    frenchName: 'Îles Åland',
    isoCode: 'AX',
  },
  {
    countryName: 'Azerbaijan',
    frenchName: 'Azerbaïdjan',
    isoCode: 'AZ',
  },
  {
    countryName: 'Bosnia and Herzegovina',
    frenchName: 'Bosnie-Herzégovine',
    isoCode: 'BA',
  },
  {
    countryName: 'Barbados',
    frenchName: 'Barbade',
    isoCode: 'BB',
  },
  {
    countryName: 'Bangladesh',
    frenchName: 'Bangladesh',
    isoCode: 'BD',
  },
  {
    countryName: 'Belgium',
    frenchName: 'Belgique',
    isoCode: 'BE',
  },
  {
    countryName: 'Burkina Faso',
    frenchName: 'Burkina Faso',
    isoCode: 'BF',
  },
  {
    countryName: 'Bulgaria',
    frenchName: 'Bulgarie',
    isoCode: 'BG',
  },
  {
    countryName: 'Bahrain',
    frenchName: 'Bahreïn',
    isoCode: 'BH',
  },
  {
    countryName: 'Burundi',
    frenchName: 'Burundi',
    isoCode: 'BI',
  },
  {
    countryName: 'Benin',
    frenchName: 'Bénin',
    isoCode: 'BJ',
  },
  {
    countryName: 'Saint Barthélemy',
    frenchName: 'Saint-Barthélemy',
    isoCode: 'BL',
  },
  {
    countryName: 'Bermuda',
    frenchName: 'Bermudes',
    isoCode: 'BM',
  },
  {
    countryName: 'Brunei Darussalam',
    frenchName: 'Brunéi Darussalam',
    isoCode: 'BN',
  },
  {
    countryName: 'Bolivia',
    frenchName: 'Bolivie (État plurinational de)',
    isoCode: 'BO',
  },
  {
    countryName: 'Brazil',
    frenchName: 'Brésil',
    isoCode: 'BR',
  },
  {
    countryName: 'Bahamas',
    frenchName: 'Bahamas',
    isoCode: 'BS',
  },
  {
    countryName: 'Bhutan',
    frenchName: 'Bhoutan',
    isoCode: 'BT',
  },
  {
    countryName: 'Bouvet Island',
    frenchName: 'Île Bouvet',
    isoCode: 'BV',
  },
  {
    countryName: 'Botswana',
    frenchName: 'Botswana',
    isoCode: 'BW',
  },
  {
    countryName: 'Belarus',
    frenchName: 'Bélarus',
    isoCode: 'BY',
  },
  {
    countryName: 'Belize',
    frenchName: 'Belize',
    isoCode: 'BZ',
  },
  {
    countryName: 'Canada',
    frenchName: 'Canada',
    isoCode: 'CA',
  },
  {
    countryName: 'Cocos (Keeling) Islands',
    frenchName: 'Îles Cocos',
    isoCode: 'CC',
  },
  {
    countryName: 'Congo (Kinshasa)',
    frenchName: 'République démocratique du Congo',
    isoCode: 'CD',
  },
  {
    countryName: 'Central African Republic',
    frenchName: 'République centrafricaine',
    isoCode: 'CF',
  },
  {
    countryName: 'Congo (Brazzaville)',
    frenchName: 'Congo',
    isoCode: 'CG',
  },
  {
    countryName: 'Switzerland',
    frenchName: 'Suisse',
    isoCode: 'CH',
  },
  {
    countryName: "Côte d'Ivoire",
    frenchName: "Côte d'Ivoire",
    isoCode: 'CI',
  },
  {
    countryName: 'Cook Islands',
    frenchName: 'Îles Cook',
    isoCode: 'CK',
  },
  {
    countryName: 'Chile',
    frenchName: 'Chili',
    isoCode: 'CL',
  },
  {
    countryName: 'Cameroon',
    frenchName: 'Cameroun',
    isoCode: 'CM',
  },
  {
    countryName: 'China',
    frenchName: 'Chine',
    isoCode: 'CN',
  },
  {
    countryName: 'Colombia',
    frenchName: 'Colombie',
    isoCode: 'CO',
  },
  {
    countryName: 'Costa Rica',
    frenchName: 'Costa Rica',
    isoCode: 'CR',
  },
  {
    countryName: 'Cuba',
    frenchName: 'Cuba',
    isoCode: 'CU',
  },
  {
    countryName: 'Cape Verde',
    frenchName: 'Cabo Verde',
    isoCode: 'CV',
  },
  {
    countryName: 'Christmas Island',
    frenchName: 'Île Christmas',
    isoCode: 'CX',
  },
  {
    countryName: 'Cyprus',
    frenchName: 'Chypre',
    isoCode: 'CY',
  },
  {
    countryName: 'Czech Republic',
    frenchName: 'République tchèque',
    isoCode: 'CZ',
  },
  {
    countryName: 'Germany',
    frenchName: 'Allemagne',
    isoCode: 'DE',
  },
  {
    countryName: 'Djibouti',
    frenchName: 'Djibouti',
    isoCode: 'DJ',
  },
  {
    countryName: 'Denmark',
    frenchName: 'Danemark',
    isoCode: 'DK',
  },
  {
    countryName: 'Dominica',
    frenchName: 'Dominique',
    isoCode: 'DM',
  },
  {
    countryName: 'Dominican Republic',
    frenchName: 'République dominicaine',
    isoCode: 'DO',
  },
  {
    countryName: 'Algeria',
    frenchName: 'Algérie',
    isoCode: 'DZ',
  },
  {
    countryName: 'Ecuador',
    frenchName: 'Équateur',
    isoCode: 'EC',
  },
  {
    countryName: 'Estonia',
    frenchName: 'Estonie',
    isoCode: 'EE',
  },
  {
    countryName: 'Egypt',
    frenchName: 'Égypte',
    isoCode: 'EG',
  },
  {
    countryName: 'Western Sahara',
    frenchName: 'République arabe sahraouie démocratique',
    isoCode: 'EH',
  },
  {
    countryName: 'Eritrea',
    frenchName: 'Érythrée',
    isoCode: 'ER',
  },
  {
    countryName: 'Spain',
    frenchName: 'Espagne',
    isoCode: 'ES',
  },
  {
    countryName: 'Ethiopia',
    frenchName: 'Éthiopie',
    isoCode: 'ET',
  },
  {
    countryName: 'Finland',
    frenchName: 'Finlande',
    isoCode: 'FI',
  },
  {
    countryName: 'Fiji',
    frenchName: 'Fidji',
    isoCode: 'FJ',
  },
  {
    countryName: 'Falkland Islands',
    frenchName: 'Malouines',
    isoCode: 'FK',
  },
  {
    countryName: 'Micronesia',
    frenchName: 'Micronésie (États fédérés de)',
    isoCode: 'FM',
  },
  {
    countryName: 'Faroe Islands',
    frenchName: 'Îles Féroé (Membre associé)',
    isoCode: 'FO',
  },
  {
    countryName: 'France',
    frenchName: 'France',
    isoCode: 'FR',
  },
  {
    countryName: 'Gabon',
    frenchName: 'Gabon',
    isoCode: 'GA',
  },
  {
    countryName: 'United Kingdom',
    frenchName: 'Royaume-Uni',
    isoCode: 'GB',
  },
  {
    countryName: 'Grenada',
    frenchName: 'Grenade',
    isoCode: 'GD',
  },
  {
    countryName: 'Georgia',
    frenchName: 'Géorgie',
    isoCode: 'GE',
  },
  {
    countryName: 'French Guiana',
    frenchName: 'Guyane',
    isoCode: 'GF',
  },
  {
    countryName: 'Guernsey',
    frenchName: 'Guernesey',
    isoCode: 'GG',
  },
  {
    countryName: 'Ghana',
    frenchName: 'Ghana',
    isoCode: 'GH',
  },
  {
    countryName: 'Gibraltar',
    frenchName: 'Gibraltar',
    isoCode: 'GI',
  },
  {
    countryName: 'Greenland',
    frenchName: 'Groenland',
    isoCode: 'GL',
  },
  {
    countryName: 'Gambia',
    frenchName: 'Gambie',
    isoCode: 'GM',
  },
  {
    countryName: 'Guinea',
    frenchName: 'Guinée',
    isoCode: 'GN',
  },
  {
    countryName: 'Guadeloupe',
    frenchName: 'Guadeloupe',
    isoCode: 'GP',
  },
  {
    countryName: 'Equatorial Guinea',
    frenchName: 'Guinée équatoriale',
    isoCode: 'GQ',
  },
  {
    countryName: 'Greece',
    frenchName: 'Grèce',
    isoCode: 'GR',
  },
  {
    countryName: 'South Georgia and South Sandwich Islands',
    frenchName: 'Géorgie du Sud-et-les Îles Sandwich du Sud',
    isoCode: 'GS',
  },
  {
    countryName: 'Guatemala',
    frenchName: 'Guatemala',
    isoCode: 'GT',
  },
  {
    countryName: 'Guam',
    frenchName: 'Guam',
    isoCode: 'GU',
  },
  {
    countryName: 'Guinea-Bissau',
    frenchName: 'Guinée-Bissau',
    isoCode: 'GW',
  },
  {
    countryName: 'Guyana',
    frenchName: 'Guyana',
    isoCode: 'GY',
  },
  {
    countryName: 'Hong Kong',
    frenchName: 'Hong Kong',
    isoCode: 'HK',
  },
  {
    countryName: 'Heard and McDonald Islands',
    frenchName: 'Îles Heard-et-MacDonald',
    isoCode: 'HM',
  },
  {
    countryName: 'Honduras',
    frenchName: 'Honduras',
    isoCode: 'HN',
  },
  {
    countryName: 'Croatia',
    frenchName: 'Croatie',
    isoCode: 'HR',
  },
  {
    countryName: 'Haiti',
    frenchName: 'Haïti',
    isoCode: 'HT',
  },
  {
    countryName: 'Hungary',
    frenchName: 'Hongrie',
    isoCode: 'HU',
  },
  {
    countryName: 'Indonesia',
    frenchName: 'Indonésie',
    isoCode: 'ID',
  },
  {
    countryName: 'Ireland',
    frenchName: 'Irlande',
    isoCode: 'IE',
  },
  {
    countryName: 'Israel',
    frenchName: 'Israël',
    isoCode: 'IL',
  },
  {
    countryName: 'Isle of Man',
    frenchName: 'Île de Man',
    isoCode: 'IM',
  },
  {
    countryName: 'India',
    frenchName: 'Inde',
    isoCode: 'IN',
  },
  {
    countryName: 'British Indian Ocean Territory',
    frenchName: "Territoire britannique de l'océan Indien",
    isoCode: 'IO',
  },
  {
    countryName: 'Iraq',
    frenchName: 'Iraq',
    isoCode: 'IQ',
  },
  {
    countryName: 'Iran',
    frenchName: 'Iran',
    isoCode: 'IR',
  },
  {
    countryName: 'Iceland',
    frenchName: 'Islande',
    isoCode: 'IS',
  },
  {
    countryName: 'Italy',
    frenchName: 'Italie',
    isoCode: 'IT',
  },
  {
    countryName: 'Jersey',
    frenchName: 'Jersey',
    isoCode: 'JE',
  },
  {
    countryName: 'Jamaica',
    frenchName: 'Jamaïque',
    isoCode: 'JM',
  },
  {
    countryName: 'Jordan',
    frenchName: 'Jordanie',
    isoCode: 'JO',
  },
  {
    countryName: 'Japan',
    frenchName: 'Japon',
    isoCode: 'JP',
  },
  {
    countryName: 'Kenya',
    frenchName: 'Kenya',
    isoCode: 'KE',
  },
  {
    countryName: 'Kyrgyzstan',
    frenchName: 'Kirghizistan',
    isoCode: 'KG',
  },
  {
    countryName: 'Cambodia',
    frenchName: 'Cambodge',
    isoCode: 'KH',
  },
  {
    countryName: 'Kiribati',
    frenchName: 'Kiribati',
    isoCode: 'KI',
  },
  {
    countryName: 'Comoros',
    frenchName: 'Comores',
    isoCode: 'KM',
  },
  {
    countryName: 'Saint Kitts and Nevis',
    frenchName: 'Saint-Kitts-et-Nevis',
    isoCode: 'KN',
  },
  {
    countryName: 'Korea, North',
    frenchName: 'République populaire démocratique de Corée',
    isoCode: 'KP',
  },
  {
    countryName: 'Korea, South',
    frenchName: 'République de Corée',
    isoCode: 'KR',
  },
  {
    countryName: 'Kuwait',
    frenchName: 'Koweït',
    isoCode: 'KW',
  },
  {
    countryName: 'Cayman Islands',
    frenchName: 'Îles Caïmans',
    isoCode: 'KY',
  },
  {
    countryName: 'Kazakhstan',
    frenchName: 'Kazakhstan',
    isoCode: 'KZ',
  },
  {
    countryName: 'Laos',
    frenchName: 'République démocratique populaire lao',
    isoCode: 'LA',
  },
  {
    countryName: 'Lebanon',
    frenchName: 'Liban',
    isoCode: 'LB',
  },
  {
    countryName: 'Saint Lucia',
    frenchName: 'Sainte-Lucie',
    isoCode: 'LC',
  },
  {
    countryName: 'Liechtenstein',
    frenchName: 'Liechtenstein',
    isoCode: 'LI',
  },
  {
    countryName: 'Sri Lanka',
    frenchName: 'Sri Lanka',
    isoCode: 'LK',
  },
  {
    countryName: 'Liberia',
    frenchName: 'Libéria',
    isoCode: 'LR',
  },
  {
    countryName: 'Lesotho',
    frenchName: 'Lesotho',
    isoCode: 'LS',
  },
  {
    countryName: 'Lithuania',
    frenchName: 'Lituanie',
    isoCode: 'LT',
  },
  {
    countryName: 'Luxembourg',
    frenchName: 'Luxembourg',
    isoCode: 'LU',
  },
  {
    countryName: 'Latvia',
    frenchName: 'Lettonie',
    isoCode: 'LV',
  },
  {
    countryName: 'Libya',
    frenchName: 'Libye',
    isoCode: 'LY',
  },
  {
    countryName: 'Morocco',
    frenchName: 'Maroc',
    isoCode: 'MA',
  },
  {
    countryName: 'Monaco',
    frenchName: 'Monaco',
    isoCode: 'MC',
  },
  {
    countryName: 'Moldova',
    frenchName: 'République de Moldova',
    isoCode: 'MD',
  },
  {
    countryName: 'Montenegro',
    frenchName: 'Monténégro',
    isoCode: 'ME',
  },
  {
    countryName: 'Saint Martin (French part)',
    frenchName: 'Saint-Martin',
    isoCode: 'MF',
  },
  {
    countryName: 'Madagascar',
    frenchName: 'Madagascar',
    isoCode: 'MG',
  },
  {
    countryName: 'Marshall Islands',
    frenchName: 'Îles Marshall',
    isoCode: 'MH',
  },
  {
    countryName: 'Macedonia',
    frenchName: 'ex-République yougoslave de Macédoine',
    isoCode: 'MK',
  },
  {
    countryName: 'Mali',
    frenchName: 'Mali',
    isoCode: 'ML',
  },
  {
    countryName: 'Myanmar',
    frenchName: 'Myanmar',
    isoCode: 'MM',
  },
  {
    countryName: 'Mongolia',
    frenchName: 'Mongolie',
    isoCode: 'MN',
  },
  {
    countryName: 'Macau',
    frenchName: 'Macao',
    isoCode: 'MO',
  },
  {
    countryName: 'Northern Mariana Islands',
    frenchName: 'Îles Mariannes du Nord',
    isoCode: 'MP',
  },
  {
    countryName: 'Martinique',
    frenchName: 'Martinique',
    isoCode: 'MQ',
  },
  {
    countryName: 'Mauritania',
    frenchName: 'Mauritanie',
    isoCode: 'MR',
  },
  {
    countryName: 'Montserrat',
    frenchName: 'Montserrat',
    isoCode: 'MS',
  },
  {
    countryName: 'Malta',
    frenchName: 'Malte',
    isoCode: 'MT',
  },
  {
    countryName: 'Mauritius',
    frenchName: 'Maurice',
    isoCode: 'MU',
  },
  {
    countryName: 'Maldives',
    frenchName: 'Maldives',
    isoCode: 'MV',
  },
  {
    countryName: 'Malawi',
    frenchName: 'Malawi',
    isoCode: 'MW',
  },
  {
    countryName: 'Mexico',
    frenchName: 'Mexique',
    isoCode: 'MX',
  },
  {
    countryName: 'Malaysia',
    frenchName: 'Malaisie',
    isoCode: 'MY',
  },
  {
    countryName: 'Mozambique',
    frenchName: 'Mozambique',
    isoCode: 'MZ',
  },
  {
    countryName: 'Namibia',
    frenchName: 'Namibie',
    isoCode: 'NA',
  },
  {
    countryName: 'New Caledonia',
    frenchName: 'Nouvelle-Calédonie',
    isoCode: 'NC',
  },
  {
    countryName: 'Niger',
    frenchName: 'Niger',
    isoCode: 'NE',
  },
  {
    countryName: 'Norfolk Island',
    frenchName: 'Île Norfolk',
    isoCode: 'NF',
  },
  {
    countryName: 'Nigeria',
    frenchName: 'Nigéria',
    isoCode: 'NG',
  },
  {
    countryName: 'Nicaragua',
    frenchName: 'Nicaragua',
    isoCode: 'NI',
  },
  {
    countryName: 'Netherlands',
    frenchName: 'Pays-Bas',
    isoCode: 'NL',
  },
  {
    countryName: 'Norway',
    frenchName: 'Norvège',
    isoCode: 'NO',
  },
  {
    countryName: 'Nepal',
    frenchName: 'Népal',
    isoCode: 'NP',
  },
  {
    countryName: 'Nauru',
    frenchName: 'Nauru',
    isoCode: 'NR',
  },
  {
    countryName: 'Niue',
    frenchName: 'Nioué',
    isoCode: 'NU',
  },
  {
    countryName: 'New Zealand',
    frenchName: 'Nouvelle-Zélande',
    isoCode: 'NZ',
  },
  {
    countryName: 'Oman',
    frenchName: 'Oman',
    isoCode: 'OM',
  },
  {
    countryName: 'Panama',
    frenchName: 'Panama',
    isoCode: 'PA',
  },
  {
    countryName: 'Peru',
    frenchName: 'Pérou',
    isoCode: 'PE',
  },
  {
    countryName: 'French Polynesia',
    frenchName: 'Polynésie française',
    isoCode: 'PF',
  },
  {
    countryName: 'Papua New Guinea',
    frenchName: 'Papouasie-Nouvelle-Guinée',
    isoCode: 'PG',
  },
  {
    countryName: 'Philippines',
    frenchName: 'Philippines',
    isoCode: 'PH',
  },
  {
    countryName: 'Pakistan',
    frenchName: 'Pakistan',
    isoCode: 'PK',
  },
  {
    countryName: 'Poland',
    frenchName: 'Pologne',
    isoCode: 'PL',
  },
  {
    countryName: 'Saint Pierre and Miquelon',
    frenchName: 'Saint-Pierre-et-Miquelon',
    isoCode: 'PM',
  },
  {
    countryName: 'Pitcairn',
    frenchName: 'Îles Pitcairn',
    isoCode: 'PN',
  },
  {
    countryName: 'Puerto Rico',
    frenchName: 'Porto Rico',
    isoCode: 'PR',
  },
  {
    countryName: 'Palestine',
    frenchName: 'Palestine',
    isoCode: 'PS',
  },
  {
    countryName: 'Portugal',
    frenchName: 'Portugal',
    isoCode: 'PT',
  },
  {
    countryName: 'Palau',
    frenchName: 'Palaos',
    isoCode: 'PW',
  },
  {
    countryName: 'Paraguay',
    frenchName: 'Paraguay',
    isoCode: 'PY',
  },
  {
    countryName: 'Qatar',
    frenchName: 'Qatar',
    isoCode: 'QA',
  },
  {
    countryName: 'Reunion',
    frenchName: 'La Réunion',
    isoCode: 'RE',
  },
  {
    countryName: 'Romania',
    frenchName: 'Roumanie',
    isoCode: 'RO',
  },
  {
    countryName: 'Serbia',
    frenchName: 'Serbie',
    isoCode: 'RS',
  },
  {
    countryName: 'Russian Federation',
    frenchName: 'Fédération de Russie',
    isoCode: 'RU',
  },
  {
    countryName: 'Rwanda',
    frenchName: 'Rwanda',
    isoCode: 'RW',
  },
  {
    countryName: 'Saudi Arabia',
    frenchName: 'Arabie saoudite',
    isoCode: 'SA',
  },
  {
    countryName: 'Solomon Islands',
    frenchName: 'Îles Salomon',
    isoCode: 'SB',
  },
  {
    countryName: 'Seychelles',
    frenchName: 'Seychelles',
    isoCode: 'SC',
  },
  {
    countryName: 'Sudan',
    frenchName: 'Soudan',
    isoCode: 'SD',
  },
  {
    countryName: 'Sweden',
    frenchName: 'Suède',
    isoCode: 'SE',
  },
  {
    countryName: 'Singapore',
    frenchName: 'Singapour',
    isoCode: 'SG',
  },
  {
    countryName: 'Saint Helena',
    frenchName: 'Sainte-Hélène, Ascension et Tristan da Cunha',
    isoCode: 'SH',
  },
  {
    countryName: 'Slovenia',
    frenchName: 'Slovénie',
    isoCode: 'SI',
  },
  {
    countryName: 'Svalbard and Jan Mayen Islands',
    frenchName: 'Svalbard et ile Jan Mayen',
    isoCode: 'SJ',
  },
  {
    countryName: 'Slovakia',
    frenchName: 'Slovaquie',
    isoCode: 'SK',
  },
  {
    countryName: 'Sierra Leone',
    frenchName: 'Sierra Leone',
    isoCode: 'SL',
  },
  {
    countryName: 'San Marino',
    frenchName: 'Saint-Marin',
    isoCode: 'SM',
  },
  {
    countryName: 'Senegal',
    frenchName: 'Sénégal',
    isoCode: 'SN',
  },
  {
    countryName: 'Somalia',
    frenchName: 'Somalie',
    isoCode: 'SO',
  },
  {
    countryName: 'Suriname',
    frenchName: 'Suriname',
    isoCode: 'SR',
  },
  {
    countryName: 'Sao Tome and Principe',
    frenchName: 'Sao Tomé-et-Principe',
    isoCode: 'ST',
  },
  {
    countryName: 'El Salvador',
    frenchName: 'El Salvador',
    isoCode: 'SV',
  },
  {
    countryName: 'Syria',
    frenchName: 'République arabe syrienne',
    isoCode: 'SY',
  },
  {
    countryName: 'Swaziland',
    frenchName: 'Swaziland',
    isoCode: 'SZ',
  },
  {
    countryName: 'Turks and Caicos Islands',
    frenchName: 'Îles Turques-et-Caïques',
    isoCode: 'TC',
  },
  {
    countryName: 'Chad',
    frenchName: 'Tchad',
    isoCode: 'TD',
  },
  {
    countryName: 'French Southern Lands',
    frenchName: 'Terres australes et antarctiques françaises',
    isoCode: 'TF',
  },
  {
    countryName: 'Togo',
    frenchName: 'Togo',
    isoCode: 'TG',
  },
  {
    countryName: 'Thailand',
    frenchName: 'Thaïlande',
    isoCode: 'TH',
  },
  {
    countryName: 'Tajikistan',
    frenchName: 'Tadjikistan',
    isoCode: 'TJ',
  },
  {
    countryName: 'Tokelau',
    frenchName: 'Tokélaou (Membre associé)',
    isoCode: 'TK',
  },
  {
    countryName: 'Timor-Leste',
    frenchName: 'Timor-Leste',
    isoCode: 'TL',
  },
  {
    countryName: 'Turkmenistan',
    frenchName: 'Turkménistan',
    isoCode: 'TM',
  },
  {
    countryName: 'Tunisia',
    frenchName: 'Tunisie',
    isoCode: 'TN',
  },
  {
    countryName: 'Tonga',
    frenchName: 'Tonga',
    isoCode: 'TO',
  },
  {
    countryName: 'Turkey',
    frenchName: 'Turquie',
    isoCode: 'TR',
  },
  {
    countryName: 'Trinidad and Tobago',
    frenchName: 'Trinité-et-Tobago',
    isoCode: 'TT',
  },
  {
    countryName: 'Tuvalu',
    frenchName: 'Tuvalu',
    isoCode: 'TV',
  },
  {
    countryName: 'Taiwan',
    frenchName: 'Taïwan / (République de Chine (Taïwan))',
    isoCode: 'TW',
  },
  {
    countryName: 'Tanzania',
    frenchName: 'République-Unie de Tanzanie',
    isoCode: 'TZ',
  },
  {
    countryName: 'Ukraine',
    frenchName: 'Ukraine',
    isoCode: 'UA',
  },
  {
    countryName: 'Uganda',
    frenchName: 'Ouganda',
    isoCode: 'UG',
  },
  {
    countryName: 'United States Minor Outlying Islands',
    frenchName: 'Îles mineures éloignées des États-Unis',
    isoCode: 'UM',
  },
  {
    countryName: 'United States of America',
    frenchName: "États-Unis d'Amérique",
    isoCode: 'US',
  },
  {
    countryName: 'Uruguay',
    frenchName: 'Uruguay',
    isoCode: 'UY',
  },
  {
    countryName: 'Uzbekistan',
    frenchName: 'Ouzbékistan',
    isoCode: 'UZ',
  },
  {
    countryName: 'Vatican City',
    frenchName: 'Saint-Siège',
    isoCode: 'VA',
  },
  {
    countryName: 'Saint Vincent and the Grenadines',
    frenchName: 'Saint-Vincent-et-les Grenadines',
    isoCode: 'VC',
  },
  {
    countryName: 'Venezuela',
    frenchName: 'Venezuela (République bolivarienne du)',
    isoCode: 'VE',
  },
  {
    countryName: 'Virgin Islands, British',
    frenchName: 'Îles Vierges britanniques',
    isoCode: 'VG',
  },
  {
    countryName: 'Virgin Islands, U.S.',
    frenchName: 'Îles Vierges des États-Unis',
    isoCode: 'VI',
  },
  {
    countryName: 'Vietnam',
    frenchName: 'Viet Nam',
    isoCode: 'VN',
  },
  {
    countryName: 'Vanuatu',
    frenchName: 'Vanuatu',
    isoCode: 'VU',
  },
  {
    countryName: 'Wallis and Futuna Islands',
    frenchName: 'Wallis-et-Futuna',
    isoCode: 'WF',
  },
  {
    countryName: 'Samoa',
    frenchName: 'Samoa',
    isoCode: 'WS',
  },
  {
    countryName: 'Yemen',
    frenchName: 'Yémen',
    isoCode: 'YE',
  },
  {
    countryName: 'Mayotte',
    frenchName: 'Mayotte',
    isoCode: 'YT',
  },
  {
    countryName: 'South Africa',
    frenchName: 'Afrique du Sud',
    isoCode: 'ZA',
  },
  {
    countryName: 'Zambia',
    frenchName: 'Zambie',
    isoCode: 'ZM',
  },
  {
    countryName: 'Zimbabwe',
    frenchName: 'Zimbabwe',
    isoCode: 'ZW',
  },
]

export const getComplaintColor = (status) => {
  let returnColor = ''

  switch (status) {
    case 'Close':
      returnColor = '#FF3E4D'
      break
    case 'Fixed By Technician':
      returnColor = '#ff8c00'
      break
    case 'Complaint To Head Office':
      returnColor = '#FF6347'
      break
    case 'Transfer To ASC':
      returnColor = '#008080'
      break
    case 'Open':
      returnColor = '#0000ff'
      break
    default:
      returnColor = 'green'
  }

  return returnColor
}
export const getWarrantyStatusColor = (warranty) => {
  let returnColor = ''

  switch (warranty) {
    case 'Out Of Warranty':
      returnColor = '#FF3E4D'
      break
    case 'Unknown Warranty':
      returnColor = '#ff8c00'
      break
    case 'In Warranty':
      returnColor = 'green'
      break
    default:
      returnColor = 'green'
  }

  return returnColor
}
export const orderStatus = [
  'Order Received',
  'Order Accepted',
  'Order Preparing',
  'Order Prepared',
  'Quality Check',
  'Quality Approved',
  'Ready To Dispatched',
  'Dispatched',
]
export const getStatusColor = (status) => {
  let returnColor = ''

  switch (status) {
    case 'Order Received':
      returnColor = 'yellow'
      break
    case 'Order Preparing':
      returnColor = '#ff8c00'
      break
    case 'Order Accepted':
      returnColor = '#26ae60'
      break
    case 'Order Prepared':
      returnColor = '#00CCCD'
      break
    case 'Quality Check':
      returnColor = '#8B78E6'
      break
    case 'Ready To Dispatched':
      returnColor = '#9c27b0'
      break
    case 'Dispatched':
      returnColor = '#96FDDE'
      break
    case 'Quality Approved':
      returnColor = '#96FDDE'
      break
    default:
      returnColor = 'green'
  }

  return returnColor
}
export const setCountryName = (countryCode) => {
  let x = _.find(countryList, (item) => item.isoCode === countryCode)
  if (x) {
    return x.countryName
  } else {
    return countryCode
  }
}
export const complaintStatus = [
  'Fixed By Technician',
  'Complaint To Head Office',
  'Transfer To ASC',
]
export const complaintStatusForAdmin = [
  'Open',
  'Fixed By Technician',
  'Complaint To Head Office',
  'Transfer To ASC',
  'Close',
]
export const warrantyStatusList = [
  'In Warranty',
  'Out Of Warranty',
  'Unknown Warranty',
  'Brand New',
]
export const getCallColor = (callType, duration) => {
  if (callType === 'outgoing' && duration == 0) {
    return {
      color: '#FBB917',
      fontWeight: 'bold',
    }
  } else if (callType === 'outgoing' && duration > 0) {
    return {
      color: '#43BE31',
      fontWeight: 'bold',
    }
  } else if (callType === 'incoming') {
    return {
      color: '#1287A5',
      fontWeight: 'bold',
    }
  } else {
    return {
      color: 'red',
      fontWeight: 'bold',
    }
  }
}

export const getLeadStageColor = (stage) => {
  let returnColor = ''

  switch (stage) {
    case 'New (Untouched)':
      returnColor = '#FF3E4D'
      break
    case 'Contacted':
      returnColor = '#ff8c00'
      break
    case 'Today Followup':
      returnColor = '#26ae60'
      break
    
    default:
      returnColor = '#1287A5'
  }

  return returnColor
}

export const getStatusStyle = (status) => {
  switch (status) {
    case 'Open':
      return {
        backgroundColor: '#1287A5',
        textAlign: 'center',
        color: 'white',
        padding: '2px 2px',
        borderRadius: '2px',
        minWidth: '30px',
      }
      break
    case 'won':
      return {
        backgroundColor: '#43BE31',
        textAlign: 'center',
        color: 'white',
        padding: '2px 2px',
        borderRadius: '2px',
        minWidth: '30px',
      }
      break
    case 'Lost':
      return {
        backgroundColor: 'red',
        textAlign: 'center',
        color: 'white',
        padding: '2px 2px',
        borderRadius: '2px',
        minWidth: '30px',
      }
      break
    default:
      return {
        backgroundColor: '#F3B431',
        color: 'white',
        textAlign: 'center',
        padding: '2px 2px',
        borderRadius: '2px',
        minWidth: '30px',
      }
  }
}
export const getLeadTypeColor = (leadType) => {
  switch (leadType) {
    case 'Facebook Ads':
      return {
        backgroundColor: '#7518ab',
        textAlign: 'center',
        color: 'white',
        padding: '2px 2px',
        borderRadius: '2px',
        minWidth: '30px',
      }
      break
    case 'aajjo':
      return {
        backgroundColor: '#43BE31',
        textAlign: 'center',
        color: 'white',
        padding: '2px 2px',
        borderRadius: '2px',
        minWidth: '30px',
      }
      break
    case 'IndiaMART Api':
      return {
        backgroundColor: '#1287A5',
        textAlign: 'center',
        color: 'white',
        padding: '2px 2px',
        borderRadius: '2px',
        minWidth: '30px',
      }
      break
    default:
      return {
        backgroundColor: '#F3B431',
        color: 'white',
        textAlign: 'center',
        padding: '2px 2px',
        borderRadius: '2px',
        minWidth: '30px',
      }
  }
}

class Request {
  constructor() {}

  error = (err) => {
    try {
      if (err.response.status === 401) {
        console.log('clearing localstorage')
        localStorage.clear()
        window.location.reload()
      }
    } catch (e) {}
  }

  login(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/login', data)
        .then((d) => {
          console.log(d, '============')
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  download(record) {
    fetch(
      `${apiUrl}/recordingStream?callingTime=${record.callingTime}&duration=${record.duration}`,
      getToken()
    ).then(function (t) {
      let header = t.headers.get('Content-Disposition')
      if (!header) {
        notification.error({ message: 'No recordings found.' })
        return
      }
      let ext = header.split('=')[1].replace(/['"]+/g, '').split('.')[1]
      let name = `Recording.${ext}`
      return t.blob().then((b) => {
        var a = document.createElement('a')
        a.href = URL.createObjectURL(b)
        a.setAttribute('download', name)
        a.click()
      })
    })
  }

  downloadWithPath(filePath) {
    fetch(
      `${apiUrl}/recordingStream?path=${filePath}&isPath=true`,
      getToken()
    ).then(function (t) {
      let header = t.headers.get('Content-Disposition')
      if (!header) {
        notification.error({ message: 'No recordings found.' })
        return
      }
      let ext = header.split('=')[1].replace(/['"]+/g, '').split('.')[1]
      let name = `Recording.${ext}`
      return t.blob().then((b) => {
        var a = document.createElement('a')
        a.href = URL.createObjectURL(b)
        a.setAttribute('download', name)
        a.click()
      })
    })
  }

  mergeLeads(data) {
    return new Promise((next) => {
      authAxios
        .post('/mergeLead', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }
  updateMergeLead(data) {
    return new Promise((next) => {
      authAxios
        .post('/updateMergeLead', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }
  addLead(data) {
    return new Promise((next) => {
      authAxios
        .post('/lead', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getAllLeads(data) {
    return new Promise((next) => {
      authAxios
        .get('/lead', { params: { ...data }, ...getToken() })
        .then((d) => {
          // console.log('from backend data us', d)
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  addUser(data) {
    return new Promise((next) => {
      authAxios
        .post('/user', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getAllROList(data) {
    return new Promise((next) => {
      authAxios
        .get('/user', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  deleteUser(data) {
    return new Promise((next) => {
      authAxios
        .delete(`/user/` + data._id, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }
  deleteInwardOutwardListItem(data) {
    return new Promise((next) => {
      authAxios
        .delete(`/inwardOutwardId/` + data._id, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }
  deleteInwardOutwardItem(data) {
    return new Promise((next) => {
      authAxios
        .delete(`/inwardId/` + data._id, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getUserById(data) {
    return new Promise((next) => {
      authAxios
        .get('/user/' + data._id, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  updateUserDetails(data) {
    return new Promise((next) => {
      authAxios
        .put('/user/' + data._id, data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getLeadById(data) {
    return new Promise((next) => {
      authAxios
        .get('/lead/' + data._id, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getLeadByActivityType(data) {
    let config = {
      params: { ...data },
      headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
    }
    return new Promise((next) => {
      authAxios
        .get('/leads/activityType', config)
        .then((d) => {
          // console.log('data from bckendn is', d)
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getOldLeadByActivityType(data) {
    let config = {
      params: { ...data },
      headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
    }
    return new Promise((next) => {
      authAxios
        .get('/old/activity', config)
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  updateLead(data) {
    return new Promise((next) => {
      authAxios
        .put('/lead/' + data._id, data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }
  getCheckList(data) {
    return new Promise((next) => {
      authAxios    
      .get('/lead/done/' + data._id, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }
  markLeadAsDone(data) {
    return new Promise((next) => {
      authAxios
        .put('/lead/done/' + data._id, data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  deleteLead(data) {
    return new Promise((next) => {
      authAxios
        .delete(`/lead/` + data._id, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  changeLeadStage(data) {
    return new Promise((next) => {
      authAxios
        .post('/changeLeadStage', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  addProduct(data) {
    return new Promise((next) => {
      authAxios
        .post('/product', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getAllProducts(data) {
    return new Promise((next) => {
      authAxios
        .get('/product', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  deleteProduct(data) {
    return new Promise((next) => {
      authAxios
        .delete(`/product/` + data._id, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getProductById(data) {
    return new Promise((next) => {
      authAxios
        .get('/product/' + data._id, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  updateProduct(data) {
    return new Promise((next) => {
      authAxios
        .put('/product/' + data._id, data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  sendPdfBySms(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/sendPdfBySms', data)
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          this.error(err)
        })
    })
  }

  sendPdfByMail(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/sendPdfByMail', data)
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          this.error(err)
        })
    })
  }

  getCategoryList() {
    return new Promise((next) => {
      authAxios
        .post('/api/category')
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  makeIsHighlightOrNot(data) {
    return new Promise((next) => {
      authAxios
        .post('/api/makeIsHighlightOrNot', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getLikeDetails(data) {
    return new Promise((next) => {
      authAxios
        .post('/likes/list/article', data, getToken())
        .then((d) => {
          next(d)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getShareDetails(data) {
    return new Promise((next) => {
      authAxios
        .post('/share/list/article', data, getToken())
        .then((d) => {
          next(d)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getViewDetails(data) {
    return new Promise((next) => {
      authAxios
        .post('/view/list/article', data, getToken())
        .then((d) => {
          next(d)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getCommentDetails(data) {
    return new Promise((next) => {
      authAxios
        .post('/comment/list/article', data, getToken())
        .then((d) => {
          next(d)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  deleteComment(data) {
    return new Promise((next) => {
      authAxios
        .delete(`/comments/delete/ByAdmin/` + data._id, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getYoutubeVideoList(data) {
    return new Promise((next) => {
      authAxios
        .get('/api/youtubeVideos', { params: { ...data } })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  deleteYoutubeVideo(data) {
    return new Promise((next) => {
      authAxios
        .post(`/api/deleteYoutubeVideo`, data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  addTodayFollowup(ids) {
    return new Promise((next) => {
      authAxios
        .post('/addTodayFollowUp', ids, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  addfollowup(data) {
    return new Promise((next) => {
      authAxios
        .post('/followup', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  addActivity(data) {
    return new Promise((next) => {
      authAxios
        .post('/activity', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getAllActivitiesByLeadId(data) {
    return new Promise((next) => {
      authAxios
        .get('/activity', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getAllFollowupsByLeadId(data) {
    return new Promise((next) => {
      authAxios
        .get('/followup', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }
  getROLeadsCount  (data) {
    return new Promise((next) => {
      authAxios
        .get('/getROLeadsCount', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getAllFollowups(data) {
    return new Promise((next) => {
      authAxios
        .get('/allFollowup', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  updateYoutubeVideo(data) {
    return new Promise((next) => {
      authAxios
        .post('/api/updateYoutubeVideo', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  uploadFile(data) {
    return new Promise((next) => {
      authAxios
        .post('/upload/uploadFile', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  uploadsList() {
    return new Promise((next) => {
      authAxios
        .get('/upload/list')
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  deleteUploads(data) {
    return new Promise((next) => {
      authAxios
        .post('/upload/delete', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  addContact(data) {
    return new Promise((next) => {
      authAxios
        .post('/contact', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getAllContacts(data) {
    return new Promise((next) => {
      authAxios
        .get('/contact', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  deleteContact(data) {
    return new Promise((next) => {
      authAxios
        .delete(`/contact/` + data._id, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getContactById(data) {
    return new Promise((next) => {
      authAxios
        .get('/contact/' + data._id, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  updateContact(data) {
    return new Promise((next) => {
      authAxios
        .put('/contact/' + data._id, data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  addQuote(data) {
    return new Promise((next) => {
      let user = JSON.parse(localStorage.getItem('user'))
      data.currentUser = user
      authAxios
        .post('/quote', data, getToken())
        .then((d) => {
          if (d.data.pdfName) {
            let linkValue = API_URL + `${d.data.pdfName}`
            setTimeout(() => {
              window.open(linkValue, 'download')
            }, 1000)
          }
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getAllQuotes(data) {
    return new Promise((next) => {
      authAxios
        .get('/quote', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  deleteQuote(data) {
    return new Promise((next) => {
      authAxios
        .delete(`/quote/` + data._id, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getQuoteById(data) {
    return new Promise((next) => {
      let user = JSON.parse(localStorage.getItem('user'))
      data.currentUser = user
      authAxios
        .get('/quote/' + data._id, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  updateQuote(data) {
    console.log('in request updatequote value is', data)
    let user = JSON.parse(localStorage.getItem('user'))
    data.currentUser = user
    return new Promise((next) => {
      authAxios
        .put('/quote/' + data._id, data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  loadContacts(data) {
    console.log('ata in load contact sid', data)
    return new Promise((next) => {
      authAxios
        .get('/allContacts', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  loadUser(data) {
    return new Promise((next) => {
      authAxios
        .get('/allUsers', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          this.error(err)
        })
    })
  }

  addTask(data) {
    return new Promise((next) => {
      authAxios
        .post('/task', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getAllTasksAssignByUser(data) {
    return new Promise((next) => {
      authAxios
        .post('/taskAssignedByUser', data, getToken())
        .then((d) => {
          next(d)
        })
        .catch((err) => {})
    })
  }

  changeTaskStatus(data) {
    return new Promise((next) => {
      authAxios
        .put('/updateTaskStatus', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          this.error(err)
        })
    })
  }

  getAllCompleteTasks(data) {
    return new Promise((next) => {
      authAxios
        .post('/task/completeTask', data, getToken())
        .then((d) => {
          next(d)
        })
        .catch((err) => {
          this.error(err)
        })
    })
  }

  saveTextNote(data) {
    return new Promise((next) => {
      // console.log('data in note req is', data)
      authAxios
        .post('/add/note', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          throw err
          this.error(err)
        })
    })
  }

  addLeadActivity(data) {
    return new Promise((next) => {
      authAxios
        .post('/add/leadActivity', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          this.error(err)
        })
    })
  }

  saveMediaFile(data) {
    return new Promise((next) => {
      authAxios
        .post('/add/mediaFiles', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          this.error(err)
        })
    })
  }

  deleteMediaFile(data) {
    return new Promise((next) => {
      authAxios
        .put('/lead/deleteFile/', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          this.error(err)
        })
    })
  }

  saveAndSendEmail(data) {
    return new Promise((next) => {
      authAxios
        .post('/add/email', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          this.error(err)
        })
    })
  }

  sendSms(data) {
    return new Promise((next) => {
      authAxios
        .post('/add/sms', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          this.error(err)
        })
    })
  }

  getAllEmailTemplates(data) {
    return new Promise((next) => {
      authAxios
        .get('/template', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          this.error(err)
        })
    })
  }

  addTemplate(data) {
    return new Promise((next) => {
      authAxios
        .post('/template', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getTemplateById(data) {
    return new Promise((next) => {
      authAxios
        .get('/template/' + data._id, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  updateTemplate(data) {
    return new Promise((next) => {
      authAxios
        .put('/template/' + data._id, data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  deleteTemplate(data) {
    return new Promise((next) => {
      authAxios
        .delete(`/template/` + data._id, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  changePassword(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/changePassword', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
          this.error(err)
        })
    })
  }

  downloadQuoteSheet(data) {
    return new Promise((next) => {
      let user = JSON.parse(localStorage.getItem('user'))
      data.user = user
      authAxios
        .post('/printQuote', data)
        .then((d) => {
          if (d.data) {
            let linkValue = API_URL + `${d.data.pdfName}`
            window.open(linkValue, 'download')
          }
          next('done')
        })
        .catch((err) => {
          this.error(err)
        })
    })
  }

  sendQuote(data) {
    return new Promise((next) => {
      authAxios
        .post('/sendQuote', data)
        .then((d) => {
          if (d.data) {
            let linkValue = API_URL + `${d.data.pdfName}`
            window.open(linkValue, 'download')
          }
          next('done')
        })
        .catch((err) => {
          this.error(err)
        })
    })
  }

  getAllCalls(data) {
    return new Promise((next) => {
      authAxios
        .get('/calls', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getClosingMonthLeads(data) {
    return new Promise((next) => {
      authAxios
        .get('/closingLeads', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getTaskCountForDashboard(data) {
    return new Promise((next) => {
      authAxios
        .get('/taskCount', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  leadsStageCount(data) {
    return new Promise((next) => {
      authAxios
        .get('/leadStageCount', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  dateWiseActivitiesCount(data) {
    return new Promise((next) => {
      authAxios
        .get('/activitiesCount', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  dateWiseFollowupCount(data) {
    return new Promise((next) => {
      authAxios
        .get('/followupCount', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  setFollowupsToFalse(data) {
    return new Promise((next) => {
      authAxios
        .put('/falseFollowups', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getAllCallLogs(data) {
    return new Promise((next) => {
      authAxios
        .get('/callLogs', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  assignLeads(_ids, data) {
    return new Promise((next) => {
      authAxios
        .put('/assignLeads', { _ids, data }, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  leadsSearch(data) {
    return new Promise((next) => {
      authAxios
        .put('/leadSearch', { data }, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  globalSearch(str, type) {
    return new Promise((next) => {
      authAxios
        .get('/globalSearch', {
          params: { search: str, type: type },
          ...getToken(),
        })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getAllRecordings(data) {
    return new Promise((next) => {
      authAxios
        .get('/recordings', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  updateRecording(id, data) {
    return new Promise((next) => {
      authAxios
        .put('/recording/' + id, data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getContactLeads(data) {
    return new Promise((next) => {
      authAxios
        .get('/leadByContactId/' + data.contactId, {
          params: { ...data },
          ...getToken(),
        })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getAllCommunicationMails(data) {
    return new Promise((next) => {
      authAxios
        .get('/communicationEmail', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  addIP(data) {
    return new Promise((next) => {
      authAxios
        .post('/ip', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getAllIps(data) {
    return new Promise((next) => {
      authAxios
        .get('/ip', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  deleteIP(data) {
    return new Promise((next) => {
      authAxios
        .delete(`/ip/` + data._id, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  saveSetting(data) {
    return new Promise((next) => {
      authAxios
        .post('/setting', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getValidateSetting() {
    return new Promise((next) => {
      authAxios
        .get('/setting', getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  activityCountDataByRO(data) {
    return new Promise((next) => {
      authAxios
        .get('/activitiesCountOfRO', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  ROPerformance(data) {
    return new Promise((next) => {
      authAxios
        .get('/ROPerformance', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  loadCountry(data) {
    return new Promise((next) => {
      authAxios
        .get('/allCountries', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          this.error(err)
        })
    })
  }

  loadState(data) {
    return new Promise((next) => {
      authAxios
        .get('/allStates', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          this.error(err)
        })
    })
  }

  loadCity(data) {
    return new Promise((next) => {
      authAxios
        .get('/allCities', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          this.error(err)
        })
    })
  }

  getCountryById(data) {
    return new Promise((next) => {
      authAxios
        .get('/country/' + data._id, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getStateById(data) {
    return new Promise((next) => {
      authAxios
        .get('/state/' + data._id, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getCityById(data) {
    return new Promise((next) => {
      authAxios
        .get('/city/' + data._id, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  addCountry(data) {
    return new Promise((next) => {
      authAxios
        .post('/country', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getAllCountry(data) {
    return new Promise((next) => {
      authAxios
        .get('/country', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  addState(data) {
    return new Promise((next) => {
      authAxios
        .post('/state', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getAllStates(data) {
    return new Promise((next) => {
      authAxios
        .get('/state', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  deleteCountry(data) {
    return new Promise((next) => {
      authAxios
        .delete(`/country/` + data._id, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  addCity(data) {
    return new Promise((next) => {
      authAxios
        .post('/city', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getAllCity(data) {
    return new Promise((next) => {
      authAxios
        .get('/city', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getSingleContactByMobile(data) {
    return new Promise((next) => {
      authAxios
        .get('/singleContactByMobile', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getSingleROActivity(data) {
    let config = {
      params: { ...data },
      headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
    }
    return new Promise((next) => {
      authAxios
        .get('/singleROActivity', config)
        .then((d) => {
          console.log('data from bckendn is', d)
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  stateWiseLeads(data) {
    let config = {
      params: { ...data },
      headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
    }
    return new Promise((next) => {
      authAxios
        .get('/stateWiseLeads', config)
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }
  getComplaintCount(data) {
    return new Promise((next) => {
      authAxios
        .get('/complaintStats', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }
  getOrderDispatchCount(data) {
    return new Promise((next) => {
      authAxios
        .get('/dasboardCount', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }
  unqualifiedLeadAssignLeadsToRO(id) {
    return new Promise((next) => {
      authAxios
        .post('/unqualifiedLeadAssignLeadsToRO', { id }, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  setUserLogoutInfo(user) {
    return new Promise((next) => {
      authAxios
        .post('/logout', user, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  addSmartFollowup(body) {
    return new Promise((next) => {
      authAxios
        .post('/smartFollowup', body, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  callUnattended(body) {
    return new Promise((next) => {
      authAxios
        .post('/callUnattended', body, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getAllLeadsByPost(data) {
    return new Promise((next) => {
      authAxios
        .post('/getAllLeadsByPost', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  ConflictsLeads(data) {
    let config = {
      params: { ...data },
      headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
    }
    return new Promise((next) => {
      authAxios
        .get('/leadsWithSameContactDiffRO', config)
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  uploadGoogleContacts() {
    return new Promise((next) => {
      authAxios
        .get('/')
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getLeadsCountForAdmin(data) {
    return new Promise((next) => {
      authAxios
        .get('/allLeadsCountForAdmin', { params: { ...data } }, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  contactSearch(data) {
    return new Promise((next) => {
      authAxios
        .post('/contactSearch', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  addManualInvoice(data) {
    return new Promise((next) => {
      let user = JSON.parse(localStorage.getItem('user'))
      data.currentUser = user
      console.log('user is', user)
      if (user && user.userType === 'RO') {
        data.quoteOwner = user._id
      }
      authAxios
        .post('/invoice', data, getToken())
        .then((d) => {
          console.log('d is', d)
          if (d.data.pdfName) {
            console.log('pdfname found')
            let linkValue = API_URL + `${d.data.pdfName}`
            setTimeout(() => {
              window.open(linkValue, 'download')
            }, 1000)
          }
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getNextInvoiceNo() {
    return new Promise((next) => {
      let user = JSON.parse(localStorage.getItem('user'))
      authAxios
        .get('/nextInvoiceId', getToken())
        .then((d) => {
          console.log('res isssssss', d)
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getNextInwardOutwardFormNo(data) {
    return new Promise((next) => {
      let user = JSON.parse(localStorage.getItem('user'))
      authAxios
        .get('/nextDispatchId', { params: { ...data }, ...getToken() })
        .then((d) => {
          console.log('res isssssss', d)
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  addInwardOutwardForm(data) {
    return new Promise((next) => {
      authAxios
        .post('/dispatch', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }
  inwardOutwardListDispatch(data) {
    return new Promise((next) => {
      authAxios
        .get('/dispatch', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          this.error(err)
        })
    })
  }
  inwardOutwardList(data) {
    return new Promise((next) => {
      authAxios
        .get('/inward', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          this.error(err)
        })
    })
  }

  getAllStockList(data) {
    return new Promise((next) => {
      authAxios
        .get('/allStock', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          this.error(err)
        })
    })
  }
  getAllStockProductList(data) {
    return new Promise((next) => {
      authAxios
        .get('/allStockProduct', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          this.error(err)
        })
    })
  }

  getInwardOutwardById(data) {
    return new Promise((next) => {
      authAxios
        .get('/inwardId/' + data._id, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }
  getDispatchInwardOutwardById(data) {
    return new Promise((next) => {
      authAxios
        .get('/dispatch/' + data._id, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }
  updateInwardOutwardForm(data) {
    return new Promise((next) => {
      authAxios
        .put('/dispatch/' + data._id, data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  deleteInwardOutward(data) {
    return new Promise((next) => {
      authAxios
        .delete(`/dispatch/` + data._id, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  addDispatch(data) {
    return new Promise((next) => {
      authAxios
        .post('/dispatchForm', data, getToken())
        .then((d) => {
          if (d.data.pdfName) {
            let linkValue = API_URL + `${d.data.pdfName}`
            setTimeout(() => {
              window.open(linkValue, 'download')
            }, 1000)
          }

          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  dispatchFormList(data) {
    return new Promise((next) => {
      authAxios
        .get('/dispatchForm', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          this.error(err)
        })
    })
  }

  getDispatchById(data) {
    return new Promise((next) => {
      authAxios
        .get('/dispatchForm/' + data._id, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  updateDispatchForm(data) {
    return new Promise((next) => {
      authAxios
        .put('/dispatchForm/' + data._id, data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  deleteDispatch(data) {
    return new Promise((next) => {
      authAxios
        .delete(`/dispatchForm/` + data._id, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getAllInvoices(data) {
    return new Promise((next) => {
      authAxios
        .get('/invoice', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  downloadInvoiceSheet(data) {
    return new Promise((next) => {
      let user = JSON.parse(localStorage.getItem('user'))
      data.user = user
      authAxios
        .post('/printInvoice', data)
        .then((d) => {
          if (d.data) {
            let linkValue = API_URL + `${d.data.pdfName}`
            window.open(linkValue, 'download')
          }
          next('done')
        })
        .catch((err) => {
          this.error(err)
        })
    })
  }

  deleteInvoice(data) {
    return new Promise((next) => {
      authAxios
        .delete(`/invoiceData/` + data._id, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getInvoiceById(data) {
    return new Promise((next) => {
      let user = JSON.parse(localStorage.getItem('user'))
      data.currentUser = user
      authAxios
        .get('/invoiceData/' + data._id, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  updateInvoice(data) {
    let user = JSON.parse(localStorage.getItem('user'))
    data.currentUser = user
    return new Promise((next) => {
      authAxios
        .put('/invoiceData/' + data._id, data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getAllCommunicationSMS(data) {
    return new Promise((next) => {
      authAxios
        .get('/communicationSMS', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  addLoginHistory(data) {
    return new Promise((next) => {
      authAxios
        .post('/loginHistory', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getAllLoginHistory(data) {
    return new Promise((next) => {
      authAxios
        .get('/loginHistory', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  deleteActivity(data) {
    return new Promise((next) => {
      authAxios
        .delete(`/deleteActivity/${data._id}/${data.activityId}`, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  leadTypeChart(data) {
    return new Promise((next) => {
      authAxios
        .get('/leadTypeChart', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  addInward(data) {
    return new Promise((next) => {
      authAxios
        .post('/inward', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }
  updateInward(data) {
    return new Promise((next) => {
      authAxios
        .post('/inwardUpdate', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }
  addDairyContact(data) {
    return new Promise((next) => {
      authAxios
        .post('/dairyContact', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  dairyContactList(data) {
    return new Promise((next) => {
      authAxios
        .get('/dairyContact', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getDairyContactById(data) {
    return new Promise((next) => {
      authAxios
        .get('/dairyContact/' + data._id, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  updateDairyContact(data) {
    return new Promise((next) => {
      authAxios
        .put('/dairyContact/' + data._id, data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  followupCalendarList(data) {
    return new Promise((next) => {
      authAxios
        .post('/followup/calendar', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  addHoliday(data) {
    return new Promise((next) => {
      authAxios
        .post('/holiday', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getAllHolidays(data) {
    return new Promise((next) => {
      authAxios
        .get('/holiday', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  deleteHoliday(data) {
    return new Promise((next) => {
      authAxios
        .delete(`/holiday/` + data._id, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  updateMultipleLeadStatus(_ids, data) {
    return new Promise((next) => {
      authAxios
        .put('/changeLeadStatus', { _ids, data }, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  taskList(data) {
    return new Promise((next) => {
      authAxios
        .get('/task', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  //return only one serial no
  fetchUniqueSerial() {
    return new Promise((next) => {
      authAxios
        .post('/product/getserialnumber', {}, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  //return [serialno]
  //params productId and qty
  fetchUniqueSerial2(data) {
    return new Promise((next) => {
      authAxios
        .post('/product/getserialnumberv2', { ...data }, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  createVoucher(data) {
    return new Promise((next) => {
      authAxios
        .post('/voucher', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
        })
    })
  }

  getVoucherById(data) {
    return new Promise((next) => {
      // let user = JSON.parse(localStorage.getItem('user'))
      // data.currentUser = user
      authAxios
        .get('/voucher/' + data._id, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  updateVoucher(data) {
    return new Promise((next) => {
      authAxios
        .put('/voucher/' + data._id, data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  AllVoucher(data) {
    return new Promise((next) => {
      authAxios
        .get('/voucher', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  deleteVoucher(data) {
    return new Promise((next) => {
      authAxios
        .delete(`/voucher/` + data._id, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  updateStatus(data) {
    console.log(data, 'dtatatatattatat')
    return new Promise((next) => {
      authAxios
        .put('/editVoucherStatus/' + data._id, data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  nextVoucherId(data) {
    console.log(data, 'dtatatatattatat')
    return new Promise((next) => {
      authAxios
        .get('/nextVoucherId', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getLeadsReport(data) {
    console.log(data)
    return new Promise((next) => {
      authAxios
        .get('/leadReport', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  inwardOutwardProducts(data) {
    console.log(data)
    return new Promise((next) => {
      authAxios
        .get('/inwardOutwardProducts', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  downloadStock(data) {
    return new Promise((next) => {
      authAxios
        .post('/stockPdf', data)
        .then((d) => {
          if (d.data) {
            let linkValue = `${API_URL}/${d.data.data}`
            window.open(linkValue, 'download')
          }
          next('done')
        })
        .catch((err) => {
          this.error(err)
        })
    })
  }
  downloadDispatch(data) {
    return new Promise((next) => {
      authAxios
        .post('/dispatchPdf', data)
        .then((d) => {
          if (d.data) {
            let linkValue = `${API_URL}/${d.data.data}`
            window.open(linkValue, 'download')
          }
          next('done')
        })
        .catch((err) => {
          this.error(err)
        })
    })
  }

  getAllServiceCenters(data) {
    return new Promise((next) => {
      authAxios
        .post('https://warranty.scizers.com/api/getDealers', data)
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  addCategory(data) {
    return new Promise((next) => {
      authAxios
        .post('/category', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  loadCategories(data) {
    return new Promise((next) => {
      authAxios
        .get('/allCategories', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          this.error(err)
        })
    })
  }

  deleteCategory(data) {
    return new Promise((next) => {
      authAxios
        .delete(`/category/` + data._id, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getAllCategories(data) {
    return new Promise((next) => {
      authAxios
        .get('/category', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  addStockProduct(data) {
    return new Promise((next) => {
      authAxios
        .post('/stockProduct', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }
  deleteStockProduct(data) {
    return new Promise((next) => {
      authAxios
        .delete(`/stockProduct/` + data._id, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  waDetailSave(data) {
    return new Promise((next) => {
      authAxios
        .post('/waSave', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getAllHistoryProducts(data) {
    return new Promise((next) => {
      authAxios
        .get('/historyProduct', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }
  getAllStockProducts(data) {
    return new Promise((next) => {
      authAxios
        .get('/stockProduct', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getAllStockLedger(data) {
    return new Promise((next) => {
      authAxios
        .get('/stockLedger', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  ledgerByProductId(data) {
    return new Promise((next) => {
      authAxios
        .get('/ledgerByProductId/' + data.productId, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  stockProductById(data) {
    return new Promise((next) => {
      authAxios
        .get('/stockProduct/' + data._id, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  updateStockProduct(data) {
    return new Promise((next) => {
      authAxios
        .put('/stockProduct/' + data._id, data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  transferLeadToExistingRO(data) {
    return new Promise((next) => {
      authAxios
        .post('/transferLeadToExistingRO', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  addOutsideQuote(data) {
    return new Promise((next) => {
      let user = JSON.parse(localStorage.getItem('user'))
      data.currentUser = user
      authAxios
        .post('/outside/quote', data, getToken())
        .then((d) => {
          if (d.data.uuid) {
            let linkValue = API_URL + `/media/${d.data.uuid}.pdf`
            setTimeout(() => {
              window.open(linkValue, 'download')
            }, 1000)
          }
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getAllOutsideQuotes(data) {
    return new Promise((next) => {
      authAxios
        .get('/outside/quote', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getOutsideQuoteById(data) {
    return new Promise((next) => {
      authAxios
        .get('/outside/quote/' + data._id, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  updateOutsideQuote(data) {
    let user = JSON.parse(localStorage.getItem('user'))
    data.currentUser = user
    return new Promise((next) => {
      authAxios
        .put('/outside/quote/' + data._id, data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  deleteOutsideQuote(data) {
    return new Promise((next) => {
      authAxios
        .delete(`/outside/quote/` + data._id, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  downloadOutsideQuoteSheet(data) {
    return new Promise((next) => {
      let user = JSON.parse(localStorage.getItem('user'))
      data.user = user
      authAxios
        .post('/outside/quote/download', data)
        .then((d) => {
          if (d.data.uuid) {
            let linkValue = API_URL + `/media/${d.data.uuid}.pdf`
            setTimeout(() => {
              window.open(linkValue, 'download')
            }, 1000)
          }
          next('done')
        })
        .catch((err) => {
          this.error(err)
        })
    })
  }

  addComplaint(data) {
    return new Promise((next) => {
      authAxios
        .post('/complaint', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getAllComplaints(data) {
    return new Promise((next) => {
      authAxios
        .get('/complaint', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getThirtyDaysOldVideos(data) {
    return new Promise((next) => {
      authAxios
        .get('/ThirtyDaysOldVideos', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  deleteUpload(data) {
    return new Promise((next) => {
      authAxios
        .delete(`/ThirtyDaysOldVideos/` + data._id, {
          params: { ...data },
          ...getToken(),
        })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  deleteEveryRow(ids) {
    return new Promise((next) => {
      authAxios
        .delete('/ThirtyDaysOldVideos', { data: { ids } }, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  // getThirtyDaysOldVideosDispatch(data) {
  //   return new Promise((next) => {
  //     authAxios
  //       .get("/ThirtyDaysOldVideosDispatch", { params: { ...data }, ...getToken() })
  //       .then((d) => {
  //         next(d.data)
  //       })
  //       .catch((err) => {
  //         next({ error: true, err })
  //         this.error(err)
  //       })
  //   })
  // }

  deleteUploadDispatch(data) {
    return new Promise((next) => {
      authAxios
        .delete(`/ThirtyDaysOldVideosDispatch/` + data._id, {
          params: { ...data },
          ...getToken(),
        })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  // deleteEveryRowDispatch(ids) {

  //   return new Promise((next) => {
  //     authAxios
  //       .delete('/ThirtyDaysOldVideosDispatch',{ data: { ids } }, getToken())
  //       .then((d) => {
  //         next(d.data)
  //       })
  //       .catch((err) => {
  //         next({ error: true, err })
  //         this.error(err)
  //       })
  //   })
  // }

  // deleteContact(data) {
  //   return new Promise((next) => {
  //     authAxios
  //       .delete("/uploadTime" + data._id, getToken())
  //       .then((d) => {
  //         next(d.data)
  //       })
  //       .catch((err) => {
  //         next({ error: true, err })
  //         this.error(err)
  //       })
  //   })
  // }

  getComplaintId(data) {
    return new Promise((next) => {
      authAxios
        .get('/getComplaintId', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  changeComplaintStatus(data) {
    return new Promise((next) => {
      authAxios
        .post('/change/complaint/status', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  addComplaintComment(data) {
    return new Promise((next) => {
      authAxios
        .post('/add/complaint/comments', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  updateComplaint(data) {
    return new Promise((next) => {
      authAxios
        .put('/complaint/' + data._id, data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  sendComplaintOtp(data) {
    return new Promise((next) => {
      authAxios
        .post('/complaint/otp', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  verifyComplaintOtp(data) {
    return new Promise((next) => {
      authAxios
        .post('/verify/complaint/otp', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getClaimHistoryArrByMachineId(data) {
    return new Promise((next) => {
      authAxios
        .get('https://warranty.scizers.com/saleProduct', {
          params: { ...data },
          ...getToken(),
        })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  addOrderDispatch(data) {
    return new Promise((next) => {
      authAxios
        .post('/order/dispatch', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  orderDispatchList(data) {
    return new Promise((next) => {
      authAxios
        .get('/order/dispatch', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  updateOrderDispatchStatusAction(data) {
    return new Promise((next) => {
      authAxios
        .post('/add/orderStatusLogs/' + data._id, data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  updateOrderAction(data) {
    return new Promise((next) => {
      authAxios
        .put('/order/dispatch/' + data._id, data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  checkPin(data) {
    return new Promise((next) => {
      authAxios
        .post('/search/pincode/' + data.pincode, data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  generateSingleBox(data) {
    return new Promise((next) => {
      authAxios
        .get(
          `/generate/singleBox/sticker/${data._id}/${data.boxId}`,
          getToken()
        )
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getAllCarriers(data) {
    return new Promise((next) => {
      authAxios
        .get('/carriers', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }
  addCarriers(data) {
    return new Promise((next) => {
      authAxios
        .post('/carriers', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }
  getAllCallSkip(data) {
    return new Promise((next) => {
      authAxios
        .get('/maxcallskip', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }
  addmaxCall(data) {
    return new Promise((next) => {
      console.log('here is data in request', data)
      authAxios
        .post('/maxcallskip', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  addReportToAdmin(data) {
    return new Promise((next) => {
      authAxios
        .post('/reportToAdmin', { name: 'hdjhdjhdjhd' }, getToken())
        .then((d) => {
          // console.log('from backend data us', d)
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getReportToAdmin() {
    return new Promise((next) => {
      authAxios
        .get('/reportToAdmin')
        .then((d) => {
          // console.log('from backend data us', d)
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  roCallConfirm(data) {
    return new Promise((next) => {
      authAxios
        .post('/roCallConfirm', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  checkLeadsCall(data) {
    return new Promise((next) => {
      authAxios
        .post('/checkLeads/call', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  reAssignVaultLeads(data) {
    return new Promise((next) => {
      authAxios
        .post('/reAssignLeads', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }
  updateVaultDate(data) {
    return new Promise((next) => {
      console.log(data)
      authAxios
        .put('/updateVaultDate/' + data._id, data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }
}

export default new Request()
