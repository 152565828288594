import React from 'react'
import {
    Input, Select, DatePicker, Modal,
    Form, notification
} from 'antd'
import moment from 'moment'
import Request from '../../../request'
import {Timeline, Descriptions} from 'antd'
import ReactQuill from 'react-quill'
import styles from './styles.less'


export const WinModal = (props) => {
    let user = {...JSON.parse(localStorage.getItem('user'))}
    const {getFieldDecorator, onCancel, updateTheLead, resetLeadId, state} = props
    return <Modal
        visible={props.state.win}
        title="Won Deal"
        okText="Save"
        cancelText="Cancel"
        onCancel={onCancel}
        onOk={() => {
            props.form.validateFields()
                .then(async (values) => {
                    values.status = 'won'
                    values.stage = 'Contacted'
                    values.leadCloseDate = Date.now()
                    let x = await updateTheLead(values)

                    if (!x.error) {
                        resetLeadId();
                        props.form.resetFields()
                        addLeadActivity({
                            values: {
                                message: `Lead declared as "Won"`,
                                leadId: state.leadData._id,
                                user: user && user.name,
                                type: 'status'
                            }
                        })
                        addLeadActivity({
                            values: {
                                message: `Lead staged as "Contacted"`,
                                leadId: state.leadData._id,
                                user: user && user.name,
                                type: 'stage'
                            }
                        })
                        Request.setFollowupsToFalse({leadId: state.leadData._id})
                        onCancel()
                    }

                })
                .catch(info => {
                    console.log('Validate Failed:', info)
                })
        }}
    >
        <p> Before you mark this deal as won, please update the following information as needed.</p>
        <Form
            layout="vertical"
            name="form_in_modal"
        >
            <Form.Item
                name="description"
                label="Won Description"
            >
                {getFieldDecorator('description', {})(<Input.TextArea/>)}
            </Form.Item>
            <Form.Item name="closeDate" label="Closed Date">
                {getFieldDecorator('closeDate', {
                    initialValue: moment(props.state.leadData.closeDate),
                    rules: [{required: true}]

                })(<DatePicker/>)}
            </Form.Item>

            <Form.Item name="invoiceNumber" label="Invoice Number">
                {getFieldDecorator('invoiceNumber', {
                    rules: [{required: true}]

                })(<Input/>)}
            </Form.Item>
            <Form.Item name="invoiceAmount" label="Invoice Amount">
                {getFieldDecorator('invoiceAmount', {
                    rules: [{required: true}]
                })(<Input/>)}
            </Form.Item>
            <Form.Item name="invoiceDate" label="Invoice Date">
                {getFieldDecorator('invoiceDate', {
                    rules: [{required: true}]
                    // initialValue: moment(props.state.leadData.closeDate)
                })(<DatePicker/>)}
            </Form.Item>
            <b>Dispatch Details</b>

            <Form.Item
                name="courierNumber"
                label="Courier Number"
            >
                {getFieldDecorator('courierNumber', {})(<Input/>)}
            </Form.Item>
            <Form.Item
                name="packedBy"
                label="Packed by"
            >
                {getFieldDecorator('packedBy', {})(<Input/>)}
            </Form.Item>
            <Form.Item
                name="checkedBy"
                label="Checked by"
            >
                {getFieldDecorator('checkedBy', {})(<Input/>)}
            </Form.Item>

        </Form>
    </Modal>

}

export const LostModal = (props) => {
    let user = {...JSON.parse(localStorage.getItem('user'))}

    const {getFieldDecorator, onCancel, updateTheLead, resetLeadId, state} = props
    return <Modal
        visible={props.state.lost}
        title="Lost Deal"
        okText="Save"
        cancelText="Cancel"
        onCancel={onCancel}
        onOk={() => {
            props.form.validateFields()
                .then(async (values) => {
                    values.status = 'Lost'
                    values.leadCloseDate = Date.now()
                    values.stage = 'Contacted'
                    let x = await updateTheLead(values)
                    if (!x.error) {
                        resetLeadId();
                        props.form.resetFields()
                        addLeadActivity({
                            values: {
                                message: `Lead declared as "Lost"`,
                                leadId: state.leadData._id,
                                user: user && user.name,
                                type: 'status'
                            }
                        })
                        // addLeadActivity({
                        //     values: {
                        //         message: `Lead staged as "Contacted"`,
                        //         leadId: state.leadData._id,
                        //         user: user && user.name,
                        //         type: 'stage'
                        //     }
                        // })
                        onCancel()
                    }
                })
                .catch(info => {
                    console.log('Validate Failed:', info)
                })
        }}
    >
        <p> Before you mark this deal as lost, please update the following information as needed.</p>
        <Form
            // form={form}
            layout="vertical"
            name="form_in_modal"
        >
            <Form.Item name="reason" label="Lost Reason" required>
                {getFieldDecorator('reason', {
                    rules: [{required: true}]

                })(<Input/>)}
            </Form.Item>
            <Form.Item
                name="description"
                label="Lost Description"
            >{getFieldDecorator('description', {})(<Input.TextArea/>)}
            </Form.Item>
            <Form.Item name="closeDate" label="Close Date">
                {getFieldDecorator('closeDate', {
                    initialValue: moment(props.state.leadData.closeDate)
                })(<DatePicker/>)}
            </Form.Item>

        </Form>
    </Modal>

}


export const addLeadActivity = async (props) => {
    let {values} = props
    values._id = props.leadId
    let x = await Request.addLeadActivity(values)
    if (!x.error) {
        // notification.success({
        //     message: x.message
        // })
    } else {
        notification.error({
            message: 'Error Saving',
            description: x.message
        })
    }
}

export const RenderActivities = (props) => {
    let {renderKey, data} = props
    return <Timeline mode="left">
        {data && data.length && data.map((activity) => {
            let newKey = `${renderKey} ${activity._id}`
            if (activity.type == 'value' || activity.type == 'status' || activity.type == 'stage')
                return <Timeline.Item
                    key={newKey}>{activity.message} by <b>{activity.user}</b> on <b>{moment(activity.createdAt).format('YYYY-MM-DD')}</b></Timeline.Item>
            else if (activity.type == 'notes')
                return <Timeline.Item
                    key={newKey}><a>{activity.message}</a> by <b>{activity.user}</b> on <b>{moment(activity.createdAt).format('YYYY-MM-DD')}</b>at <b>{moment(activity.createdAt).format('hh:mm')}</b></Timeline.Item>
        })}

    </Timeline>


}

export const NoteActivityModal = (props) => {
    const {cancelActivityModal, state} = props
    return <Modal
        visible={props.state.noteModal}
        title="Note"
        onCancel={cancelActivityModal}
        cancelButtonProps={{style: {display: 'none'}}}
        okButtonProps={{style: {display: 'none'}}}
    >
        <div dangerouslySetInnerHTML={{__html: props.state.noteMessage}}></div>
    </Modal>

}

export const EmailActivityModal = (props) => {
    const {cancelActivityModal, state} = props
    return <Modal
        visible={props.state.emailModal}
        title="Email"
        onCancel={cancelActivityModal}
        cancelButtonProps={{style: {display: 'none'}}}
        okButtonProps={{style: {display: 'none'}}}
    >
        {/* <div dangerouslySetInnerHTML={{__html: props.state.noteMessage}}></div>
     */}
        <Descriptions>
            <Descriptions.Item className={styles.antddescription}><b style={{fontSize: '16px'}}>Receiver :</b> <br/>
                <div style={{
                    border: '1px solid #DAE0E2',
                    margin: '5px',
                    backgroundColor: '#DAE0E2',
                    width: '100%',
                    padding: '3px'
                }}>{state.emailActivity.emailId}<br/></div>

                <b style={{fontSize: '16px'}}>Subject :</b><br/>
                <div style={{
                    border: '1px solid #DAE0E2',
                    margin: '5px',
                    backgroundColor: '#DAE0E2',
                    width: '100%',
                    padding: '3px'
                }}>{state.emailActivity.emailSubject}<br/></div>

                <b style={{fontSize: '16px'}}> Message :</b>
                <div style={{
                    border: '1px solid #DAE0E2',
                    margin: '5px',
                    backgroundColor: '#DAE0E2',
                    width: '100%',
                    padding: '3px'
                }} dangerouslySetInnerHTML={{__html: state.emailActivity.emailMessage}}></div>
            </Descriptions.Item>
        </Descriptions>
    </Modal>

}
export const TextActivityModal = (props) => {
    const {cancelActivityModal, state} = props
    return <Modal
        visible={props.state.textActivityModal}
        title="Sms"
        onCancel={cancelActivityModal}
        cancelButtonProps={{style: {display: 'none'}}}
        okButtonProps={{style: {display: 'none'}}}
    >
        <Descriptions>
            <Descriptions.Item className={styles.antddescription}><b style={{fontSize: '16px'}}>Receiver :</b> <br/>
                <div style={{
                    border: '1px solid #DAE0E2',
                    margin: '5px',
                    backgroundColor: '#DAE0E2',
                    width: '100%',
                    padding: '3px'
                }}>{state.textActivity.textNumber}<br/></div>

                <b style={{fontSize: '16px'}}>Text :</b><br/>
                <div style={{
                    border: '1px solid #DAE0E2',
                    margin: '5px',
                    backgroundColor: '#DAE0E2',
                    width: '100%',
                    padding: '3px'
                }}>{state.textActivity.textMessage}<br/></div>

            </Descriptions.Item>
        </Descriptions>
    </Modal>

}

export const FollowUpActivityModal = (props) => {
    const {cancelActivityModal, state} = props
    return <Modal
        visible={props.state.followUpModal}
        title="Followup"
        onCancel={cancelActivityModal}
        cancelButtonProps={{style: {display: 'none'}}}
        okButtonProps={{style: {display: 'none'}}}
    >
        <Descriptions>
            <Descriptions.Item className={styles.antddescription} style={{width: '100%'}}>
                <b style={{fontSize: '16px'}}>Followup Date :</b> <br/>
                <div style={{
                    border: '1px solid #DAE0E2',
                    margin: '5px',
                    backgroundColor: '#DAE0E2',
                    width: '100%',
                    padding: '3px'
                }}>{moment(state.followupActivity.followUpDate).format('ll')}<br/></div>
                <b style={{fontSize: '16px'}}>Time :</b><br/>
                <div style={{
                    border: '1px solid #DAE0E2',
                    margin: '5px',
                    backgroundColor: '#DAE0E2',
                    width: '100%',
                    padding: '3px'
                }}>{state.followupActivity.followUpTime}<br/></div>
                {/* <b style={{fontSize: '16px'}}> Description :</b><br/>
                <div style={{
                    border: '1px solid #DAE0E2',
                    margin: '5px',
                    backgroundColor: '#DAE0E2',
                    width: '100%',
                    padding: '3px'
                }}>{state.followupActivity.followUpDescription}<br/></div> */}

            </Descriptions.Item>
        </Descriptions>


    </Modal>
}
export const SmartFollowUpModal = (props) => {
    const {cancelActivityModal, state} = props
    return <Modal
        visible={props.state.SmartFollowUpModal}
        title="Followup"
        onCancel={cancelActivityModal}
        cancelButtonProps={{style: {display: 'none'}}}
        okButtonProps={{style: {display: 'none'}}}
    >
        <Descriptions>
            <Descriptions.Item className={styles.antddescription} style={{width: '100%'}}>
                <b style={{fontSize: '16px'}}>Followup Date :</b> <br/>
                <div style={{
                    border: '1px solid #DAE0E2',
                    margin: '5px',
                    backgroundColor: '#DAE0E2',
                    width: '100%',
                    padding: '3px'
                }}>{moment(state.followupActivity.followUpDate).format('ll')}<br/></div>
                <b style={{fontSize: '16px'}}>Time :</b><br/>
                <div style={{
                    border: '1px solid #DAE0E2',
                    margin: '5px',
                    backgroundColor: '#DAE0E2',
                    width: '100%',
                    padding: '3px'
                }}>{state.followupActivity.followUpTime}<br/></div>
            </Descriptions.Item>
        </Descriptions>


    </Modal>
}
export const DealActivityModal = (props) => {
    const {cancelActivityModal, state} = props
    return <Modal
        visible={props.state.dealActivityModal}
        title="Activity"
        onCancel={cancelActivityModal}
        cancelButtonProps={{style: {display: 'none'}}}
        okButtonProps={{style: {display: 'none'}}}
    >
        <Descriptions>
            <Descriptions.Item className={styles.antddescription}><b style={{fontSize: '16px'}}>Title :</b> <br/>
                <div style={{
                    border: '1px solid #DAE0E2',
                    margin: '5px',
                    backgroundColor: '#DAE0E2',
                    width: '100%',
                    padding: '3px'
                }}>{state.dealActivity.activityTitle}<br/></div>
                <b style={{fontSize: '16px'}}>Date :</b><br/>
                <div style={{
                    border: '1px solid #DAE0E2',
                    margin: '5px',
                    backgroundColor: '#DAE0E2',
                    width: '100%',
                    padding: '3px'
                }}>{state.dealActivity.activityDate ? moment(state.dealActivity.activityDate).format('ll') : ''}<br/>
                </div>
                <b style={{fontSize: '16px'}}>Time :</b><br/>
                <div style={{
                    border: '1px solid #DAE0E2',
                    margin: '5px',
                    backgroundColor: '#DAE0E2',
                    width: '100%',
                    padding: '3px'
                }}>{state.dealActivity.activityTime}<br/></div>
                <b style={{fontSize: '16px'}}> Description :</b><br/>
                <div style={{
                    border: '1px solid #DAE0E2',
                    margin: '5px',
                    backgroundColor: '#DAE0E2',
                    width: '100%',
                    padding: '3px'
                }}>{state.dealActivity.activityDescription}</div>
            </Descriptions.Item>
        </Descriptions>


    </Modal>
}

export const WhatsAppActivityModal = (props) => {
    const {cancelActivityModal, state} = props
    return <Modal
        visible={props.state.whatsAppModal}
        title="WhatsApp Text"
        onCancel={cancelActivityModal}
        cancelButtonProps={{style: {display: 'none'}}}
        okButtonProps={{style: {display: 'none'}}}
    >
        <div>{props.state.whatsAppActivity.whatsAppMessage}</div>
    </Modal>

}


export const ComplaintActivityModal = (props) => {
    const {cancelActivityModal, state} = props
    return <Modal
        visible={props.state.complaintModal}
        title="Complaint Details"
        onCancel={cancelActivityModal}
        cancelButtonProps={{style: {display: 'none'}}}
        okButtonProps={{style: {display: 'none'}}}
    >
        <div>{props.state.complaintActivity.message}</div>
    </Modal>

}


