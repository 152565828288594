import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import {
  Select,
  Tabs,
  Form,
  Input,
  Button,
  Modal,
  Checkbox,
  Typography,
  Divider,
  Popconfirm,
} from 'antd'
import { notification } from 'antd/lib/index'
import { FormUtils as GetAllFormFields } from 'sz-react-utils'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import _ from 'lodash'
import moment from 'moment'
import async from 'async'

import AddActivity from '../../activity/add/index'
import AttachFile from '../../emails/attachFiles'
import { addLeadActivity } from './helpers'
import Request from '../../../request'
import { API_URL } from '../../../request'
import { followUpTime } from '../../followup/helpers'
import styles from '../../emails/styles.less'

const striptags = require('striptags')
const { Paragraph } = Typography
const Option = { Select }
const { TabPane } = Tabs
const atValues = [
  { id: 1, value: 'contactName' },
  { id: 2, value: 'ROOfficer' },
]

@Form.create()
class TabComp extends PureComponent {
  validateTabFields = () => {
    const { form } = this.props
    let { emailChecked, smsChecked, whatsAppChecked, message } = this.state
    let followUpDate = form.getFieldValue('followUpDate')
    
    if (!followUpDate) {
      notification.error({ message: 'Please complete followup to proceed!' })
      return false
    }

    if (emailChecked || smsChecked || whatsAppChecked) {
      if (!message) {
        notification.error({ message: 'Please add text to message' })
        return false
      }
    }

    return true
  }

  saveNote = async () => {
    let { textNote } = this.state
    let { leadData, setNewData, user, getLeadByTypes } = this.props
    let leadId = leadData && leadData._id

    let x = await Request.saveTextNote({
      message: textNote,
      leadId,
      checkLead: true,
    })
    if (!x.error) {
      notification.success({
        message: x.message,
      })
      this.setState({ textNote: '' })

      await addLeadActivity({
        values: {
          message: `Note Added`,
          leadId: leadId,
          user: user && user.name,
          type: 'notes',
        },
      })
      await getLeadByTypes('all')

      setNewData(leadId)
      this.setState({ activeKey: '0' })
    } else {
      notification.error({
        message: 'Error Saving',
        description: x.message,
      })
    }
    var element = document.getElementsByClassName('ql-editor')
    element[0].innerHTML = ''
    this.setState({ textNote: '' })
  }

  saveFollowUp = async () => {
    const { dispatch, form, leadData, user, getLeadByTypes } = this.props

    let leadId = leadData && leadData._id
    let stage = leadData && leadData.stage

    const followUpDate = form.getFieldValue('followUpDate')
    if (followUpDate) {
      form.validateFieldsAndScroll(async (err, valData) => {
        if (!err) {
          let values = _.clone(valData)

          values.leadId = leadId
          // values.followUpTime = this.state.followUpTime
          values.isAllow = true
          let x = null
          x = await Request.addfollowup(values)

          if (!x.error) {
            notification.success({
              message: x.message,
            })

            this.setState({ activeKey: '0' })
            await getLeadByTypes('all')
            this.props.setNewDataFollowup(leadData._id)

            if (stage && stage === 'New (Untouched)') {
              await Request.updateLead({
                _id: leadId,
                stage: 'Followup (New Untouched)',
                dontCheckLead: true,
              })
              await addLeadActivity({
                values: {
                  message: `Lead staged as "Followup (New Untouched)"`,
                  leadId: leadId,
                  user: user && user.name,
                  type: 'stage',
                },
              })
            }
            if (
              (stage && stage === 'Today Followup') ||
              (stage && stage === 'Overdue Followup')
            ) {
              let { data } = await Request.getLeadById({ _id: leadId })
              await Request.updateLead({
                _id: leadId,
                stage: data && data.existingStage,
                dontCheckLead: true,
              })
              await addLeadActivity({
                values: {
                  message: `Lead staged as "${data && data.existingStage}"`,
                  leadId: leadId,
                  user: user && user.name,
                  type: 'stage',
                },
              })
            }
            this.props.form.resetFields()
            // this.setState({ rerenderFollowupTime: Date.now(), followUpTime: '' })

            await addLeadActivity({
              values: {
                message: `Followup Added for date ${moment(
                  values.followUpDate
                ).format('YYYY-MM-DD')}`,
                leadId: leadId,
                user: user && user.name,
                type: 'followUp',
              },
            })
          } else {
            notification.error({
              message: 'Error Saving',
              description: x.message,
            })
          }
        }
      })
    }
  }

  unqualifyLead = async () => {
    let { leadData } = this.props
    let leadId = leadData && leadData._id

    let x = await Request.updateMultipleLeadStatus(
      [leadId],
      {status: 'Unqualified'}
    )

    if (!x.error) {
      notification.success({
        message: x.message,
      })
    } else {
      notification.error({
        message: 'Error Saving',
        description: x.message,
      })
    }
  }

  onFinishValueForm = async (e) => {
    let { leadData, setNewData, user, updateTheLead, getLeadByTypes } = this.props
    let leadId = leadData && leadData._id

    e.preventDefault()
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        if (
          (!values.value || !values.currency) ||
          values.value == '' ||
          values.currency == ''
        ) {
          notification.error({
            message: 'Please input both Value and Currency before submitting',
          })
          return
        }
        this.setState({ loaderValueForm: true })
        let x = await updateTheLead(values)
        this.setState({ loaderValueForm: false })
        if (!x.error) {
          if (leadData.value !== values.value) {
            addLeadActivity({
              values: {
                message: `Value changed from ${
                  leadData.value ? leadData.value : 'empty'
                } to ${values.value}`,
                leadId: leadData._id,
                user: user && user.name,
                type: 'value',
              },
            })

            this.props.form.resetFields()
            await getLeadByTypes('all')

            setNewData(leadId)
            this.setState({ activeKey: '0' })
          } else {
            addLeadActivity({
              values: {
                message: `Currency changed from ${leadData.currency} to ${values.currency}`,
                leadId: leadData._id,
                user: user && user.name,
                type: 'currency',
              },
            })
          }
        }
      }
    })
  }

  saveEmail = async () => {
    if (this.state.emailChecked || this.state.smsChecked || this.state.whatsAppChecked) {
      if (!this.state.message) {
        notification.error({ message: 'Please add text to message' })
        return;
      }
    }

    let { leadData } = this.props
    let leadId = leadData && leadData._id
    let user = JSON.parse(localStorage.getItem('user'))

    let messageWithNewlines = this.state.message && this.state.message.replace(/<\/p>/gi, '%0A');

    messageWithNewlines = messageWithNewlines && messageWithNewlines.replace(/<br\s*\/?>/gi, '%0A');

    let whatsappMessage = striptags(messageWithNewlines)

    whatsappMessage = whatsappMessage.slice(0, whatsappMessage.length - 3)

    if (this.state.emailChecked) {
      this.saveAndShareEmails()
    }
    if (this.state.smsChecked) {
      this.sendSms()
    }
    if (this.state.whatsAppChecked) {
      let x = await Request.checkLeadsCall({ leadId: leadId })
      if (!x.error) {
        whatsappMessage = this.extractText(whatsappMessage)
        window.open(
          `http://api.whatsapp.com/send?phone=${this.state.mobile}&text=${whatsappMessage}&source=&data=`
        )
        await addLeadActivity({
          values: {
            message: `Whatsapp sent to "${this.state.mobile}"`,
            whatsAppMessage: whatsappMessage,
            leadId: leadId,
            user: user && user.name,
            type: 'whatsApp',
          },
        })
        var element = document.getElementsByClassName('ql-editor')
        if (element && element.length) {
          element[1].innerHTML = ''
        }

        // this.leadUpdatedAsContacted()
      } else {
        notification.error({
          message: 'Error Saving',
          description: x.message,
        })
      }
    }
  }

  getTimeOptions = (date) => {
    let afterDate = date || moment()
    let timeOptionsArr = []
    if (afterDate.format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')) {
      async.forEach(
        followUpTime,
        (doc, cb) => {
          let compareTime = moment(doc, ['h:mma'])

          if (moment().add('hour', 1).isBefore(compareTime)) {
            timeOptionsArr.push(doc)
          }
          cb()
        },
        () => {
          this.setState({
            timeOptionsArr,
          })
        }
      )
    } else {
      this.setState({
        timeOptionsArr: followUpTime,
      })
    }
  }

  saveAndShareEmails = async () => {
    let user = JSON.parse(localStorage.getItem('user'))
    let { subject, emailId, message } = this.state
    let { handleAddEmail, leadId, leadData } = this.props
    if (!emailId) {
      return notification.error({ message: 'Enter emailId!' })
    }
    if (!subject) {
      return notification.error({ message: 'Enter subject!' })
    }
    if (!message) {
      return notification.error({ message: 'Enter message!' })
    }
    this.setState({ btnLoader: true })
    var element = document.getElementsByClassName('ql-editor')
    element[1].innerHTML = ''
    this.setState({ loading: true, emailLoader: true })
    let x = await Request.saveAndSendEmail({
      message,
      leadId,
      subject,
      emailId,
      leadData,
    })

    this.setState({ loading: false })
    if (!x.error) {
      notification.success({
        message: x.message,
      })
      this.setState({
        message: '',
        subject: '',
        emailId: '',
        templateTitle: null,
        emailLoader: false,
      })
      await addLeadActivity({
        values: {
          message: `Email sent to user "${emailId}" with subject "${subject}"`,
          leadId: leadId,
          user: user && user.name,
          type: 'email',
        },
      })

      if (handleAddEmail) {
        handleAddEmail()
      }
    } else {
      notification.error({
        message: 'Error Saving',
        description: x.message,
      })
      this.setState({ emailLoader: false })
    }
  }

  extractText = (message) => {
    message = message.replace(/{%=ROOfficer%}/g, this.state.currentUser.name)
    message = message.replace(/{%=contactName%}/g, this.state.contactName)
    return message
  }

  leadUpdatedAsContacted = async () => {
    let user = JSON.parse(localStorage.getItem('user'))
    let { handleAddEmail, leadId, leadData } = this.props
    // if (
    //   leadData &&
    //   leadData.productList &&
    //   !leadData.productList.length &&
    //   !leadData.otherProduct
    // ) {
    //   return notification.error({
    //     message: 'Please add Product',
    //   })
    // }

    let condition =
      leadData &&
      (leadData.stage === 'New (Untouched)' ||
        leadData.stage === 'Followup (New Untouched)') &&
      ((leadData.productList && leadData.productList.length) ||
        leadData.otherProduct)
    if (condition) {
      await Request.updateLead({ _id: leadId, new: false, stage: 'Contacted' })
      await addLeadActivity({
        values: {
          message: `Lead staged as "Contacted"`,
          leadId: leadData._id,
          user: user && user.name,
          type: 'stage',
        },
      })
    }
  }

  sendSms = async () => {
    let message = striptags(this.state.message)
    let mobile = this.state.mobile
    let { handleAddEmail, leadId, leadData } = this.props
    let user = JSON.parse(localStorage.getItem('user'))
    this.setState({ smsLoader: true })
    let xx = await Request.checkLeadsCall({ leadId: leadId })
    if (xx.error) {
      return notification.error({
        message: 'Error Saving',
        description: xx.message,
      })
    }

    let x = await Request.sendSms({ message, mobile, leadId, leadData })
    if (!x.error) {
      notification.success({
        message: 'Sms sent',
      })
    }
    var element = document.getElementsByClassName('ql-editor')
    element[1].innerHTML = ''
    this.setState({ smsLoader: false })
    await addLeadActivity({
      values: {
        message: `Sms sent to "${mobile}" `,
        leadId: leadId,
        user: user && user.name,
        type: 'message',
        textMessage: message,
        textNumber: mobile,
      },
    })
    if (handleAddEmail) {
      handleAddEmail()
    }
  }

  getAllTemplates = async () => {
    let x = await Request.getAllEmailTemplates({ results: 100000 })
    if (!x.error) {
      this.setState({ templateList: x.data })
    } else {
      notification.error({
        message: 'Error Saving',
        description: x.message,
      })
    }
  }

  mentionModule = {
    allowedChars: /^[A-Za-z\s]*$/,
    mentionDenotationChars: ['@'],
    source: function (searchTerm, renderList, mentionChar) {
      let values
      if (mentionChar === '@') {
        values = atValues
      }
      if (searchTerm.length === 0) {
        renderList(values, searchTerm)
      } else {
        const matches = []
        for (let i = 0; i < values.length; i++)
          if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase()))
            matches.push(values[i])
        renderList(matches, searchTerm)
      }
    },
    mentionContainerClass: styles.mentionContainer,
    onSelect: (item, insertItem) => {
      insertItem({ ...item, value: `{%=${item.value}%}` })
    },
    showDenotationChar: false,
  }

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      textNote: '',
      followUpTime: '',

      activeKey: '0',
      activeKey2: '0',
      currentStage: 0,

      loading: false,

      submitEmail: false,
      submitActivity: false,
      submitAttachFile: false,

      templateList: [],
      addTemplate: false,
      emailChecked: false,
      whatsAppChecked: false,
      smsChecked: false,
      emailLoader: false,
      smsLoader: false,
      message: '',

      loaderValueForm: false,

      rerenderFollowupTime: Date.now(),
    }
  }

  async componentDidMount() {
    let user = JSON.parse(localStorage.getItem('user'))
    
    let { leadData } = this.props
    this.setState({
      emailId: leadData.contact ? leadData.contact.email : '',
      mobile:
      leadData && leadData.mobile
      ? leadData.mobile
      : leadData.contact
      ? leadData.contact.mobile
      : '',
      contactName: leadData.contact ? leadData.contact.firstName || '' : '',
      currentUser: user,
    })

    this.getAllTemplates()

    // this.getTimeOptions()
  }

  render() {
    const { leadData, setNewData, user, getLeadByTypes } = this.props
    const {
      form: { getFieldDecorator },
    } = this.props

    let leadId = leadData && leadData._id

    const { timeOptionsArr } = this.state

    const inputTypes = {
      fields: [
        {
          label: 'FollowUp Date',
          key: 'followUpDate',
          type: 'date',
          required: true,
          // rightComp: (
          //   <Select
          //     style={{
          //       width: 125,
          //       marginLeft: '3px',
          //       marginBottom: '13px',
          //     }}
          //     allowClear
          //     placeholder="Select Time"
          //     key={this.state.rerenderFollowupTime}
          //     onChange={(time) => {
          //       this.setState({ followUpTime: time }, () => {})
          //     }}>
          //     {timeOptionsArr &&
          //       timeOptionsArr.map((val, idn) => {
          //         return (
          //           <Option key={idn} value={val}>
          //             {val}
          //           </Option>
          //         )
          //       })}
          //   </Select>
          // ),

          customProps: {
            // onChange: (val) => {
            //   this.getTimeOptions(val)
            // },
            // disabledDate: (d) => !d || d.isBefore(moment().add(-1, 'day'))
            disabledDate: (d) => {
              const today = moment()
              const minDate = moment().add(45, 'days')

              // Disable dates earlier than today
              if (!d || d.isBefore(today.startOf('day'))) {
                return true
              }

              // Disable dates later than 45 days from today
              if (d.isAfter(minDate)) {
                return true
              }

              return false
            },
          },
        },
      ],
    }

    const formItemLayout = {
      labelCol: {
        xs: { span: 8 },
        sm: { span: 8 },
        md: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 8 },
        sm: { span: 8 },
        md: { span: 8 },
      },
    }

    const onTemplateCancel = () => {
      this.setState({ addTemplate: false })
    }
    const onTemplateCreate = async () => {
      //empty template
      let x = await Request.addTemplate({
        title: this.state.templateTitle,
        content: this.state.templateContent,
      })
      var element = document.getElementsByClassName('ql-editor')
      element[2].innerHTML = ''
      this.setState({
        addTemplate: false,
        templateContent: '',
        templateTitle: '',
      })

      if (!x.error) {
        await this.getAllTemplates()
        notification.success({
          message: x.message,
        })
      } else {
        notification.error({
          message: 'Error Saving',
          description: x.message,
        })
      }
    }

    const { templateList } = this.state

    const setSubmitEmail = (value) => {
      this.setState({ submitEmail: value })
    }

    const setSubmitActivity = (value) => {
      this.setState({ submitActivity: value })
    }

    const setSubmitAttachFile = (value) => {
      this.setState({ submitAttachFile: value })
    }

    return (
      <React.Fragment>
        <Tabs
          style={{ overflow: 'unset' }}
          type="line"
          activeKey={this.state.activeKey}
          defaultActiveKey="0"
          onTabClick={(key) => {
            if (key === '1') {
              this.setState({
                whatsAppChecked: true,
              })
            }

            if (key !== '1') {
              this.setState({
                whatsAppChecked: false,
              })
            }

            this.setState({ activeKey: key })
          }}>
          <TabPane tab={'Note'} key={0}>
            <React.Fragment>
              <div style={{ height: '110px' }}>
                <ReactQuill
                  style={{ backgroundColor: 'white' }}
                  placeholder={'Write something here...'}
                  value={this.state.textNote}
                  onChange={(text) => {
                    const isEmpty = text === '<p><br></p>'
                    this.setState({ textNote: isEmpty ? '' : text })
                    // this.setState({ textNote: text })
                  }}
                />
              </div>
              <Button
                type="primary"
                style={{ marginTop: '5px' }}
                loading={this.state.loading}
                onClick={async () => {

                  let { textNote } = this.state

                    this.setState({ loading: true })
                    if (textNote) {
                      await this.saveNote()
                    } else {
                      notification.error({
                        message: 'Please add note to submit',
                      })
                    }
                    this.props.handleTabSubmit()
                    this.setState({ loading: false })
                }}>
                Submit
              </Button>
            </React.Fragment>
          </TabPane>

          <TabPane tab={'Whatsapp'} key={1}>
            <div>
              <React.Fragment>
                <div style={{ marginBottom: '15px' }}>
                  <Checkbox
                    onChange={() => {
                      this.setState({
                        emailChecked: !this.state.emailChecked,
                      })
                    }}>
                    Email
                  </Checkbox>
                  <Checkbox
                    checked={this.state.whatsAppChecked}
                    onChange={() => {
                      this.setState({
                        whatsAppChecked: !this.state.whatsAppChecked,
                      })
                    }}>
                    WhatsApp
                  </Checkbox>
                  <Checkbox
                    onChange={() => {
                      this.setState({ smsChecked: !this.state.smsChecked })
                    }}>
                    SMS
                  </Checkbox>
                </div>
                <Select
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  style={{
                    width: 225,
                    marginBottom: '10px',
                  }}
                  placeholder="Select Template"
                  onChange={(content) => {
                    var element = document.getElementsByClassName('ql-editor')
                    if (content == undefined) {
                      element[1].innerHTML = ''
                      return
                    }
                    content = `${content}`
                    this.setState({ templateContent: '' }, () => {
                      element[1].innerHTML = content
                    })
                  }}>
                  {templateList &&
                    templateList.map((val, idn) => {
                      return (
                        <Option key={idn} value={val.content}>
                          {val.title}
                        </Option>
                      )
                    })}
                </Select>
                <Button
                  style={{ marginLeft: '10px' }}
                  type="primary"
                  icon="plus"
                  size="medium"
                  onClick={() => {
                    this.setState({ addTemplate: true })
                  }}
                />

                <Paragraph
                  style={{ backgroundColor: 'white', padding: '10px' }}
                  editable={{
                    onChange: async (val) => {
                      this.setState({ mobile: val }, async () => {
                        await Request.updateLead({
                          _id: leadId,
                          new: false,
                          mobile: this.state.mobile,
                        }).then((x) => {})
                      })
                    },
                  }}>
                  {this.state.mobile
                    ? this.state.mobile
                    : 'Click to add Mobile Number'}
                </Paragraph>

                <Input
                  style={{ marginBottom: '10px' }}
                  disabled={!this.state.emailChecked}
                  value={this.state.emailId}
                  placeholder={'Enter Email'}
                  onChange={(v) => {
                    this.setState({ emailId: v.target.value })
                  }}
                />

                <Input
                  style={{ marginBottom: '10px' }}
                  value={this.state.subject}
                  disabled={!this.state.emailChecked}
                  placeholder={'Enter subject'}
                  onChange={(v) => {
                    this.setState({ subject: v.target.value })
                  }}
                />
                <ReactQuill
                  placeholder={'Type message...'}
                  onChange={(text) => {
                    this.setState({ message: text })
                  }}
                  modules={{ mention: this.mentionModule }}
                />

                <div className={styles.quoteAdd}>
                  <a href={`/quote/add?leadId=${leadId}`} target={'_blank'}>
                    <Button type="primary" style={{ marginTop: '5px' }}>
                      Add Quote
                    </Button>
                  </a>
                </div>

                <Modal
                  visible={this.state.addTemplate}
                  title="Create a new Email Template"
                  okText="Create"
                  onCancel={onTemplateCancel}
                  onOk={onTemplateCreate}>
                  <Input
                    style={{ marginBottom: '10px' }}
                    value={this.state.templateTitle}
                    placeholder={'Please input the title of Template!'}
                    onChange={(v) => {
                      this.setState({ templateTitle: v.target.value })
                    }}
                  />
                  <ReactQuill
                    placeholder={'Type Content...'}
                    onChange={(text) => {
                      this.setState({ templateContent: text })
                    }}
                    modules={{ mention: this.mentionModule }}
                  />
                </Modal>
              </React.Fragment>
            </div>
            <Button
              type="primary"
              style={{ marginTop: '5px' }}
              loading={this.state.loading}
              onClick={async () => {
                let validator = this.validateTabFields()

                if (validator) {
                  if (this.state.emailChecked || this.state.smsChecked || this.state.whatsAppChecked) {
                    if (this.state.message) {
                      await this.saveEmail()
                    }
                  }
                  this.setState({ activeKey: '0' })

                  this.props.handleTabSubmit()
                  this.setState({ loading: false })
                }
              }}>
              Submit
            </Button>
          </TabPane>

          <TabPane tab={'Unqualify'} key={2}>
            <Popconfirm title="Are you sure to Unqualify this Lead?" onConfirm={this.unqualifyLead}>
              <Button type='primary' ghost>Unqualify Lead</Button>
            </Popconfirm>
            <Divider />
          </TabPane>

          {leadData && leadData.stage == 'New (Untouched)' ? null : (
            <TabPane tab={'FollowUp'} key={3}>
              <React.Fragment>
                <Form
                  onSubmit={this.handleSubmit}
                  hideRequiredMark
                  style={{ marginTop: 8 }}>
                  <GetAllFormFields
                    apiurl={API_URL}
                    inputSchema={inputTypes}
                    formItemLayout={formItemLayout}
                    getFieldDecorator={getFieldDecorator}
                  />
                </Form>
              </React.Fragment>
              <Button
                type="primary"
                style={{ margin: '5px 0 0 33.33%' }}
                loading={this.state.loading}
                onClick={async () => {
                  let validator = this.validateTabFields()

                  const { form } = this.props
                  let followUpDate = form.getFieldValue('followUpDate')

                  if (validator) {
                    this.setState({ loading: true })
                    if (followUpDate) {
                      await this.saveFollowUp()
                    }

                    this.props.handleTabSubmit()
                    this.setState({ loading: false })
                  }
                }}>
                Submit
              </Button>
            </TabPane>
          )}

          <TabPane tab={'File'} key={4}>
            <AttachFile
              hideTitle={true}
              // hideSubmit={true}
              submitAttachFile={this.state.submitAttachFile}
              setSubmitAttachFile={setSubmitAttachFile}
              leadId={leadData && leadData._id}
              filesData={leadData && leadData.files ? leadData.files : []}
              handleAttachFile={async () => {
                this.setState({ activeKey: '0' })
                await addLeadActivity({
                  values: {
                    message: `File attached`,
                    leadId: leadData._id,
                    user: user && user.name,
                    type: 'file',
                  },
                })
                await getLeadByTypes('all')
                setNewData(leadData._id)
              }}
            />
          </TabPane>

          <TabPane tab={'Value'} key={5}>
            <Form labelCol={{ span: 8 }} wrapperCol={{ span: 6 }} onSubmit={this.onFinishValueForm}>
              <Form.Item name="value" label="Value">
                {getFieldDecorator('value', {
                })(
                  <Select placeholder="Select Value" allowClear>
                    <Option value="Below 10K">Below 10K</Option>
                    <Option value="From 10K to 50K">From 10K to 50K</Option>
                    <Option value="From 50K to 1 Lakh">From 50K to 1 Lakh</Option>
                    <Option value="Above 1 Lakh">Above 1 Lakh</Option>
                  </Select>
                )}
              </Form.Item>
              <Form.Item name="currency" label="Currency">
                {getFieldDecorator('currency', {
                })(
                  <Select placeholder="Select Currency" allowClear>
                    <Option value="INR-₹">INR-₹</Option>
                    <Option value="DOLLAR-$">DOLLAR-$</Option>
                  </Select>
                )}
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" style={{ margin: '5px 0 0 133.5%' }} loading={this.state.loaderValueForm}>
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </TabPane>
        </Tabs>

        {/* <Button
          type="primary"
          style={{ marginTop: '5px' }}
          loading={this.state.loading}
          onClick={async () => {
            let validator = this.validateTabFields()

            const { form } = this.props
            let { textNote, followUpTime } = this.state
            let followUpDate = form.getFieldValue('followUpDate')

            if (validator) {
              this.setState({ loading: true })
              if (textNote) {
                await this.saveNote()
              }
              if (followUpDate && followUpTime) {
                await this.saveFollowUp()
              }

              if (this.state.emailChecked || this.state.smsChecked || this.state.whatsAppChecked) {
                if (this.state.message) {
                  await this.saveEmail()
                }
              }

              this.setState({
                submitActivity: true,
                submitAttachFile: true,
              })
              this.props.handleTabSubmit()
              this.setState({ loading: false })

              setTimeout(() => {
                this.setState({
                  submitActivity: false,
                  submitAttachFile: false,
                })
              }, 10)
            }
          }}>
          Submit
        </Button> */}
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  categories: global.categories,
  pathname: router.location.pathname,
})
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TabComp)
